import React from 'react';
import PropTypes from 'prop-types';
//import ReactGA from 'react-ga';
import { Elements, StripeProvider } from 'react-stripe-elements';
import UpdateCard from './UpdateCard';
//import {CardNumberElement,CardExpiryElement,CardCVCElement, PostalCodeElement, injectStripe} from 'react-stripe-elements';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import green from '@material-ui/core/colors/green';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import { TEST_STRIPE_KEY, STRIPE_KEY } from '../helpers';
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  avatar: {
    margin: 0,
  },
  greenAvatar: {
    margin: 0,
    color: '#fff',
    backgroundColor: green[500],
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 15
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },

  button: {
    margin: theme.spacing.unit,

  }


});


class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      name: '',
      address_country: '',
      address_line1: '',
      address_city: '',
      address_state: '',
      country_code: '',
      paymentprocessing: false,
      errormsg: 0
    };

  }
  state = {
    opennew: false,
    invoices: [],
    posts: [],
    subscription: '',
    fname: '',
    lname: '',
    email: '',
    unsubscribed: '',
    free: '',
    admin: false,
    errormsg: ''
  };


  handleCloseUpdate = () => {
    this.setState({ opennew: false });
  };

  handleClickOpenUpdate = () => {
    this.setState({ opennew: true });
  }





  render() {

    const { classes } = this.props;

    return (

      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Button variant="contained" size="large" color="primary" className={classes.button} onClick={() => { this.handleClickOpenUpdate() }}>
            Update Payment details
          </Button>
          <Dialog
            open={this.state.opennew}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleCloseUpdate}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>

              <StripeProvider apiKey={STRIPE_KEY}>
                <div className="example">
                  <Elements>
                    <UpdateCard dialogclose={this.state.opennew} />
                  </Elements>
                </div>
              </StripeProvider>

            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseUpdate} color="primary">
                Close
            </Button>
            </DialogActions>
          </Dialog>

        </Grid>
      </Grid>

    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Settings);

