import React, { Component } from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CheckoutForm from './CheckoutForm'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import { Redirect } from 'react-router-dom'
import Footer from './Footer'
import { TEST_STRIPE_KEY, STRIPE_KEY } from '../helpers'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
})

class PaymentForm extends Component {
  state = {
    logoutclick: false,
  }

  handleClicklogout() {
    this.setState({ logoutclick: true })
  }
  render() {
    const { logoutclick } = this.state
    if (logoutclick) {
      return <Redirect to="/logout" />
    }
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Link to="/paymentplan">
              <Typography
                className="headerlogo"
                variant="h6"
                color="inherit"
                noWrap
              >
                <img className="logoimage" src="/stt-logo.svg" alt="img.svg" />
                <span> Search Texas Tax</span>
              </Typography>
            </Link>
            <Button
              className="headerbutton"
              color="inherit"
              onClick={() => {
                this.handleClicklogout()
              }}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <StripeProvider apiKey={STRIPE_KEY}>
          <div className="payment-plan">
            <Elements>
              <CheckoutForm
                formType="checkoutForm"
                subscriptionPlan={this.props.match.params.subscriptionPlan}
              />
            </Elements>
          </div>
        </StripeProvider>
        <Footer />
      </div>
    )
  }
}

PaymentForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(PaymentForm)
