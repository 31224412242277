import React from 'react'
import axios from 'axios'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    margin: 0,
  },
  pinkAvatar: {
    margin: 0,
    color: '#fff',
    backgroundColor: '#d50000',
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 13
  },
  greenAvatar: {
    margin: 0,
    color: '#fff',
    backgroundColor: '#0d47a1',
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 12
  },
  recent: {

  },
  fab: {
    margin: theme.spacing.unit * 2
  },
  absolute: {
    position: "relative",
    bottom: theme.spacing.unit * 10,
    left: theme.spacing.unit * 170
  }
});


class RecentlyViewed extends React.Component {
  state = {
    posts: [],
    token: '',
    dataLoadingStatus: 'loading',
    chartData: [],
    value: 0,
    type: '',
    portfolio: '',
    errormsg: false
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ errormsg: false });
    this.setState({ portfolio: '' });
  };



  componentDidMount() {

    document.title = "Recently Viewed- Search Texas Tax";
    ReactGA.initialize('UA-80211377-3');
    ReactGA.pageview(window.location.pathname);

    axios({
      method: 'get',
      url: `${apiUrl}/rest/history`,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
      }
    })
      .then((res) => {
        const posts = res.data;

        try {
           //  filter out the posts history that are the same location number and name, so when user 
          // clicks on same property it does not show more than once
          let filterPosts = posts.filter((post, index, self) =>
            index === self.findIndex((t) => (
              t.location_number === post.location_number && t.location_name === post.location_name
            ))
          );
          this.setState({ posts: filterPosts });

        } catch(err){
            handleError(err, pathname)
        }
      }).catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });

  }

  render() {

    const { classes } = this.props;

    return (

      <Grid item xs={12} className="recent-tfoot" >
        <MaterialTable
          columns={[
            {
              title: 'Hotel Name',
              render: rowData => {
                if (rowData.type === '0') {
                  return (<Link to={`/stt/hotelinfo/${rowData.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${rowData.taxpayer_number}/${rowData.location_number}`} onClick={() => window.scrollTo(0, 0)} className={classes.recent}>{rowData.location_name}</Link>)
                } else {
                  return (<Link to={`/stt/mbthotelinfo/${rowData.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${rowData.taxpayer_number}/${rowData.location_number}`} onClick={() => window.scrollTo(0, 0)} className={classes.recent}>{rowData.location_name}</Link>)
                }
              }
            },
            {
              title: 'Type',
              render: rowData => {
                if (rowData.type === '0') { return (<Avatar className={classes.pinkAvatar}>Hotel</Avatar>) }
                else { return (<Avatar className={classes.greenAvatar}>MBT</Avatar>) }
              }
            },
          ]}
          data={this.state.posts}
          title='Recently Viewed'
          options={{
            pageSize: 5,
            search: false,
            columnsButton: false,
            exportButton: false,
            showEmptyDataSourceMessage: true
          }}

        />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Grid>

    );
  }
}

RecentlyViewed.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RecentlyViewed);
