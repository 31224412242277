import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SearchBox from './SearchBox';
import RecentlyViewed from './RecentlyViewed';
import Grid from '@material-ui/core/Grid';
import SingleSearch from './SingleSearch';


const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});
;

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      querySearchBox: "",
      // querySearchBox1: "",
      viewTable: false,
      viewMBTTable: false,
      latestTime: ''
    };
    //console.log("Search props");
    //console.log(this.props);
  };

  componentDidMount() {
    document.title = "Search - Search Texas Tax";
  }



  // GETTABLE AXIOS URL -- GETMBTTABLE.Js
  // `https://appnode.searchtexastax.com/rest${this.props.queryFromSearch1}`


  render() {
    return (
      <div>
        <Grid container spacing={3} className="searchgrid">
          <React.Fragment>
            <SingleSearch className="search-box" callbackFromParent={this.Callback} />
            <SearchBox className="search-box" callbackFromParent={this.Callback} />
          </React.Fragment>
          <RecentlyViewed />
        </Grid>
      </div>
    );

  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Search);
