import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import axios from 'axios';
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Footer from '../components/Footer';
import MainRoute from '../components/MainRoute';
import { mailFolderListItems, otherMailFolderListItems } from './tileData';
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom'
import FreshdeskWidget from '@personare/react-freshdesk-widget';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiUrl } from '../helpers';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing.unit * 3,
    width: '100%',
    minHeight: '100vh',
    height: 'auto'
  },
  link: {
    color: "white",
    ":hover": {
      textDecoration: "none",

    },
  },
  progress: {
    // margin: theme.spacing.unit * 2,
  },

});

function CircularIndeterminate(props) {
  const { classes } = props;
  return (
    <div>
      <CircularProgress className={classes.progress} />

    </div>
  );
}

class ResponsiveDrawer extends React.Component {
  constructor() {
    super();
    this.state = {
      mobileOpen: false,
      fname: '',
      isMobile: false,
      loadingAccount: true
    };
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  componentDidMount() {
    //console.log("in mount");
    // first check if weare in mobile view
    this.setState({ isMobile: true });
    axios({
      method: 'get',
      url: `${apiUrl}/rest/history/users`,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
      }
    }).then((res) => {
      //console.log(res.data);
      if (res.data) {
        this.setState({ fname: "Howdy, " + res.data[0].fname + "!" });
        this.setState({ loadingAccount: false })
      }

    }).catch(e => {
      // console.log(e);
      axios({
        method: 'post',
        headers: { authorization: 'Bearer ' + localStorage.getItem('token') },
        url: `${apiUrl}/rest/errorlog`,
        data: { error: JSON.stringify(e), url: window.location.href }
      }).then((res) => {
        // console.log(res);
      })
      window.location.replace('/logout');
    });

  }
  handleDrawerToggle = () => {
    if (this.state.isMobile) {
      this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    }
  };

  handleDrawerClose() {
    if (this.state.isMobile) {
      this.setState({ mobileOpen: false });
    }
  }

  componentWillUnmount() {
    this.setState({ isMobile: false });
  }

  render() {
    const { classes, theme } = this.props;
    const { loadingAccount } = this.state;

    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <List onClick={this.handleDrawerClose}>{mailFolderListItems}</List>
        </List>
        <Divider />
        <List onClick={this.handleDrawerClose}>
          {otherMailFolderListItems}
        </List>
      </div>
    );



    return (


      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/stt/search">
              <Typography className="headerlogo" variant="h6" color="inherit" noWrap>

                <img className="logoimage" src="/stt-logo.svg" alt="stt-logo.svg" />
                <span>Search Texas Tax</span>
              </Typography>
            </Link>
            <Button className="welcome" color="inherit" ><Link className={classes.link} to="/stt/settings">{this.state.fname}</Link> </Button>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className="sttnew">
          <div className={classes.toolbar} />
          <MainRoute />
          <FreshdeskWidget url="https://searchtexastax.freshdesk.com" type="pop-up">
            <button className="freshbutton" color="primary"> Help & Support</button>
          </FreshdeskWidget>
          <Footer />
        </main>
      </div>
    );
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);