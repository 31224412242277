/* eslint-disable no-new-object */
import React from 'react'
import axios from 'axios'
import MaterialTable from 'material-table'
import Paper from '@material-ui/core/Paper';
import { Chart } from "react-google-charts";
import Icon from '@material-ui/core/Icon';
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import { dollar } from './helper';
import ReactGA from 'react-ga';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sum } from './helper';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({

  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  Red: {
    color: 'red',
    top: '5px',
    position: 'relative',
    fontSize: '21px!important',
    fontWeight: '1000!important'
  },
  Green: {
    color: 'green',
    top: '5px',
    position: 'relative',
    fontSize: '21px!important',
    fontWeight: '1000!important'
  },
  Grey: {
    color: 'gray',
    position: 'relative',
    fontSize: '21px!important',
    marginBottom: '5px'

  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  }
});
const durations = [
  {
    value: "2016",
    label: "1 year"
  },
  {
    value: "2015",
    label: "3 years"
  },
  {
    value: "2013",
    label: "5 years"
  },
  {
    value: "2008",
    label: "10 years"
  },
  {
    value: "2000",
    label: "All"
  }
];
const durationscity = [
  {
    value: "2016",
    label: "1 year"
  },
  {
    value: "2015",
    label: "3 years"
  },
  {
    value: "2013",
    label: "5 years"
  },
  {
    value: "2008",
    label: "10 years"
  },
  {
    value: "2000",
    label: "All"
  }
];
class County extends React.Component {
  state = {
    posts: [],
    newposts: [],
    citygraph: [],
    token: '',
    dataLoadingStatus: 'loading',
    chartData: [],
    chartDataCity: [],
    rows: [],
    columns: [],
    dataLoadingStatusCity: 'loading',
    value: 0,
    cityposts: [],
    duration: "2016",
    durationcity: "2016",
    yearposts: []
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  componentDidMount() {
    document.title = this.props.match.params.county + ",TX - Search Texas Tax";
    ReactGA.initialize('UA-80211377-3');
    ReactGA.pageview(window.location.pathname);
    let columns = [
      { type: 'date', label: 'Month' }];
    let rows = [];
    axios.get(`${apiUrl}/rest/basic/citiesincounty/${this.props.match.params.county}`,
      {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
        }
      })
      .then(res => {
        const cityposts = res.data;
        //cities graph
        const citypostsgraph = res.data;
        //this.setState({ citypostsgraph:citypostsgraph });


        //let count = 1;
        //code to get columns
        citypostsgraph.forEach(post => {

          var index = columns.findIndex(obj => obj.label === post.location_city);
          ////console.log(index);
          if (index === -1) {
            const a = {
              type: 'number',
              label: post.location_city
            }
            columns.push(a);
          }


        });
        ////console.log(columns);

        try {
           //Got columns. code below is to get rows
          for (var p = 1; p < columns.length; p++) {

            ////console.log(columns[p].label);
            if (p === 1) {
              citypostsgraph.forEach(post => {
                if (post.location_city === columns[p].label) {
                  if (post.year > 2016 && post.year <= 2018) {
                    var date = new Date(post.year, post.month - 1, 1);
                    rows.push([date + "", post.Total_Revenue]);
                  }
                  //count = count+1;
                }
              });
            } else {
              citypostsgraph.forEach(post => {
                if (post.location_city === columns[p].label) {
                  if (post.year > 2016 && post.year <= 2018) {
                    var date = new Date(post.year, post.month - 1, 1);

                    let ab = new Date(Date.parse(new Date(date)));
                    let i = 0;
                    let flag = 0;
                    //  ////console.log(rows.length);
                    for (i = 0; i < rows.length; i++) {
                      if (rows[i][0].includes(ab)) {
                        rows[i][p] = post.Total_Revenue;
                        //   ////console.log("Dates Matched");
                        flag = 1;
                        break;
                      }
                    }
                    if (flag === 0) {
                      rows.push([date + ""]);
                      rows[rows.length - 1][p] = post.Total_Revenue;
                    }
                  }
                }//End of if condition
              });//End of forEach
            }//End of count condition
          }//End of columns loop
          this.setState({ cityposts: cityposts });

        } catch(err){
          handleError(err, pathname);
        }
      }).then(res => {
        ////console.log(rows);
        //code for cities graph to replace empty values with null
        this.setState({ rows: rows, columns: columns });
        try {

          for (let m = 0; m < this.state.rows.length; m++) {
            //this.setState({ rows})
            this.state.rows[m][0] = new Date(this.state.rows[m][0]);
            for (let n = 1; n < this.state.columns.length; n++) {
              if (!this.state.rows[m][n])
                this.state.rows[m][n] = null;
            }
          }
          this.state.rows = this.state.rows.sort(function (a, b) {
            return a[0] - b[0];
          });
          // //console.log(rows);
          this.setState({
            chartDataCity: [columns, ...rows],
            dataLoadingStatusCity: 'ready',
          })
        } catch(err){
          handleError(err, pathname);
        }
       
      }).catch((e) => {
        handleError(e, pathname);
      })

    axios.get(`${apiUrl}/rest/basic/county/${this.props.match.params.county}`,
      {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
        }
      })
      .then(res => {

        try {

          const columns = [
            { type: 'date', label: 'Month' },
            { type: 'number', label: 'Total Revenue' },
            { type: 'number', label: 'RevPar' },
          ]
          let rows = []

          for (let row of res.data) {
            let { date, Total_Revenue, RevPar } = row
            date = new Date(row.year + "-" + row.month);
            if (row.year > 2016 && row.year <= 2018) {
              date = new Date(row.year, row.month - 1, 1);
              rows.push([date, Total_Revenue, RevPar])
            }
          }
          this.setState({
            chartData: [columns, ...rows],
            dataLoadingStatus: 'ready',
          })
  
          const rawposts = res.data;
          const posts = res.data;
  
          this.setState({ posts: posts });
          var i = 0;
          var j = 0;
          var a = 0;
          var newposts = [];
          for (i = 0; i < posts.length; i++) {
  
  
            if (a === 0) {
  
              newposts.push(new Object());
              newposts[newposts.length - 1].year = posts[i].year;
              if (posts[i].month === 1) {
                newposts[newposts.length - 1].january = posts[i].Total_Revenue;
                ////////consolelog(temp['January']);
              }
              if (posts[i].month === 2) {
                newposts[newposts.length - 1].february = posts[i].Total_Revenue;
              }
              if (posts[i].month === 3) {
                newposts[newposts.length - 1].march = posts[i].Total_Revenue;
              }
              if (posts[i].month === 4) {
                newposts[newposts.length - 1].april = posts[i].Total_Revenue;
              }
              if (posts[i].month === 5) {
                newposts[newposts.length - 1].may = posts[i].Total_Revenue;
              }
              if (posts[i].month === 6) {
                newposts[newposts.length - 1].june = posts[i].Total_Revenue;
              }
              if (posts[i].month === 7) {
                newposts[newposts.length - 1].july = posts[i].Total_Revenue;
              }
              if (posts[i].month === 8) {
                newposts[newposts.length - 1].august = posts[i].Total_Revenue;
              }
              if (posts[i].month === 9) {
                newposts[newposts.length - 1].september = posts[i].Total_Revenue;
              }
              if (posts[i].month === 10) {
                newposts[newposts.length - 1].october = posts[i].Total_Revenue;
              }
              if (posts[i].month === 11) {
                newposts[newposts.length - 1].november = posts[i].Total_Revenue;
              }
              if (posts[i].month === 12) {
                newposts[newposts.length - 1].december = posts[i].Total_Revenue;
              }
              // //////consolelog("Inside a");
              //newposts.push(temp);
              a = 1;
              continue;
            }
  
            for (j = 0; j < newposts.length; j++) {
              if (newposts[j].year === posts[i].year) {
  
                if (posts[i].month === 1) {
                  newposts[j].january = posts[i].Total_Revenue;
                  ////////consolelog(temp['January']);
                }
                if (posts[i].month === 2) {
                  newposts[j].february = posts[i].Total_Revenue;
                  ////////consolelog(newposts[j]);
                }
                if (posts[i].month === 3) {
                  newposts[j].march = posts[i].Total_Revenue;
                }
                if (posts[i].month === 4) {
                  newposts[j].april = posts[i].Total_Revenue;
                }
                if (posts[i].month === 5) {
                  newposts[j].may = posts[i].Total_Revenue;
                }
                if (posts[i].month === 6) {
                  newposts[j].june = posts[i].Total_Revenue;
                }
                if (posts[i].month === 7) {
                  newposts[j].july = posts[i].Total_Revenue;
                }
                if (posts[i].month === 8) {
                  newposts[j].august = posts[i].Total_Revenue;
                }
                if (posts[i].month === 9) {
                  newposts[j].september = posts[i].Total_Revenue;
                }
                if (posts[i].month === 10) {
                  newposts[j].october = posts[i].Total_Revenue;
                }
                if (posts[i].month === 11) {
                  newposts[j].november = posts[i].Total_Revenue;
                }
                if (posts[i].month === 12) {
                  newposts[j].december = posts[i].Total_Revenue;
                  a = 0;
                }
                break;
  
              }
            }
          }
          var yearposts = [];
          var currentd = new Date();
          var current = currentd.getFullYear();
   
          for (var k = 0; k < newposts.length; k++) {
            yearposts.push(new Object());
            yearposts[k].year = newposts[k].year;
  
            if (newposts[k].year === current) {
              yearposts[k].total_revenue = sum(newposts[k]);
            }
            else {
              //  yearposts[k].total_revenue = newposts[k].january+newposts[k].february+newposts[k].march+newposts[k].april+newposts[k].may+newposts[k].june+
              //                               newposts[k].july+newposts[k].august+newposts[k].september+newposts[k].october+newposts[k].november+newposts[k].december;
              yearposts[k].total_revenue = sum(newposts[k]);
            }
          }
          //  //console.log(yearposts);
          for (var l = 0; l < yearposts.length; l++) {
            if (l === 0 || yearposts[l].year === current) {
              yearposts[l].totalRevChange = '$0 (0%)';
              //  yearposts[l].RevPARChange = '0';
            } else {
              yearposts[l].totalRevChange = '$ ' + (yearposts[l].total_revenue - yearposts[l - 1].total_revenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' (' + ((((yearposts[l].total_revenue / yearposts[l - 1].total_revenue) * 100) - 100).toFixed(2)) + "%)";
              // yearposts[l].RevPARChange = '$ '+(yearposts[l].RevPar-yearposts[l-1].RevPar).toFixed(2)+ ' (' + ((((yearposts[l].RevPar/yearposts[l-1].RevPar)*100)-100).toFixed(2))+"%)";
            }
          }
          this.setState({ 
            yearposts,
            newposts,
            rawposts
          });

        } catch(err){
          handleError(err, pathname);
        }
        
      }).catch(e => {
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }


  handleChange1 = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    axios.get(`${apiUrl}/rest/basic/county/${this.props.match.params.county}`,
      {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
        }
      })
      .then(res => {

        try {
          const columns = [
            { type: 'date', label: 'Month' },
            { type: 'number', label: 'Total Revenue' },
            { type: 'number', label: 'RevPar' },
          ]
          let rows = []
          var d = new Date();
          var n = d.getFullYear();
          for (let row of res.data) {
            let { date, Total_Revenue, RevPar } = row
            date = new Date(row.year + "-" + row.month);
            if (row.year > event.target.value && row.year <= n) {
              date = new Date(row.year, row.month - 1, 1);
              rows.push([date, Total_Revenue, RevPar])
            }
          }
          this.setState({
            chartData: [columns, ...rows],
            dataLoadingStatus: 'ready',
          })

        } catch(err){
          handleError(err, pathname)
        }
      }).catch(e => {
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }


  handleChangecity = name => event => {
    this.setState({
      [name]: event.target.value,
    });

  
    let columns = [
      { type: 'date', label: 'Month' }];
    let rows = [];
    axios.get(`${apiUrl}/rest/basic/citiesincounty/${this.props.match.params.county}`,
      {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
        }
      })
      .then(res => {

        try {

          //cities graph
          const citypostsgraph = res.data;
          //code to get columns
          citypostsgraph.forEach(post => {
            var index = columns.findIndex(obj => obj.label === post.location_city);
            if (index === -1) {
              const a = {
                type: 'number',
                label: post.location_city
              }
              columns.push(a);
            }
          });

          //Got columns. code below is to get rows
          for (var p = 1; p < columns.length; p++) {

            ////console.log(columns[p].label);
            if (p === 1) {
              citypostsgraph.forEach(post => {
                if (post.location_city === columns[p].label) {
                  if (post.year > event.target.value && post.year <= 2018) {
                    var date = new Date(post.year, post.month - 1, 1);
                    rows.push([date + "", post.Total_Revenue]);
                  }
                  //count = count+1;
                }
              });
            } else {
              citypostsgraph.forEach(post => {
                if (post.location_city === columns[p].label) {
                  if (post.year > event.target.value && post.year <= 2018) {
                    var date = new Date(post.year, post.month - 1, 1);

                    let ab = new Date(Date.parse(new Date(date)));
                    let i = 0;
                    let flag = 0;
                    //  ////console.log(rows.length);
                    for (i = 0; i < rows.length; i++) {
                      if (rows[i][0].includes(ab)) {
                        rows[i][p] = post.Total_Revenue;
                        //   ////console.log("Dates Matched");
                        flag = 1;
                        break;
                      }
                    }
                    if (flag === 0) {
                      rows.push([date + ""]);
                      rows[rows.length - 1][p] = post.Total_Revenue;
                    }
                  }
                }//End of if condition
              });//End of forEach
            }//End of count condition
          }//End of columns loop
          ////console.log(rows);
        } catch(err){
          handleError(err, pathname)
        }
      }).then(res => {
        try {

           //code for cities graph to replace empty values with null
          this.setState({ rows: rows, columns: columns });
          for (let m = 0; m < this.state.rows.length; m++) {
            this.state.rows[m][0] = new Date(this.state.rows[m][0]);
            for (let n = 1; n < this.state.columns.length; n++) {
              if (!this.state.rows[m][n])
                this.state.rows[m][n] = null;
            }
          }
          this.state.rows = this.state.rows.sort(function (a, b) {
            return a[0] - b[0];
          });
          ////console.log(rows);
          this.setState({
            chartDataCity: [columns, ...rows],
            dataLoadingStatusCity: 'ready',
          })

        } catch(err){
          handleError(err, pathname)
        }
      }).catch((e) => {
        handleError(e, pathname);
        //window.location.replace('/login');
      })

  }

  render() {
    let chartdisplay;
    if (this.state.dataLoadingStatus === "ready") {
      chartdisplay = (
        <div>
          <TextField
            id="outlined-select-currency"
            select
            value={this.state.duration}
            onChange={this.handleChange1('duration')}
            SelectProps={{
              MenuProps: {

              },
            }}
            margin="normal"

          >
            {durations.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Chart
            chartType="Line"
            loader={<div>Loading Chart</div>}
            width={'100%'}
            height={'400px'}
            data={this.state.chartData}
            options={{
              chartArea: { height: '100%', width: '90%' },
              hAxis: {
                format: 'yyyy',
              },
              vAxis: {
                format: 'short',
              },
              title: 'Debt incurred over time.',
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: 'Total_Revenue' },

              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Receipts: { label: 'Monthly Revenue' },
                  all: {
                    format: {
                      pattern: 'currency'
                    }


                  }
                },
                x: {

                  all: {
                    format: {
                      pattern: "MMM dd, yyyy"
                    }


                  }
                }
              }

            }}
            rootProps={{ 'data-testid': '2' }}

          />







        </div>


      )
    }
    else {
      chartdisplay = (
        <div className="loadingparent"> <CircularProgress className="loading" size={50} /></div>
      )
    }
    //End of first Graph

    //City Graph:
    let chartdisplaycity;
    if (this.state.dataLoadingStatusCity === "ready") {
      chartdisplaycity = (
        <div>
          <TextField
            id="outlined-select-currency"
            select


            value={this.state.durationcity}
            onChange={this.handleChangecity('durationcity')}
            SelectProps={{
              MenuProps: {

              },
            }}

            margin="normal"

          >
            {durationscity.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Chart
            chartType="Line"
            loader={<div>Loading Chart</div>}
            width={'100%'}
            height={'400px'}
            data={this.state.chartDataCity}
            options={{
              chartArea: { height: '100%', width: '100%' },
              hAxis: {
                format: 'yyyy',
              },
              vAxis: {
                format: 'short',
              },

              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Receipts: { label: 'Monthly Revenue' },
                  all: {
                    format: {
                      pattern: 'currency'
                    }
                  }
                },
                x: {

                  all: {
                    format: {
                      pattern: "MMM dd, yyyy"
                    }


                  }
                }
              }

            }}
            rootProps={{ 'data-testid': '2' }}

          />
        </div>


      )
    }
    else {
      chartdisplaycity = (
        <div className="loadingparent"> <CircularProgress className="loading" size={50} /></div>
      )
    }
    //End of City Graph

    const { classes } = this.props;
    const { value } = this.state;


    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className="data_title">
              <div className="row">
                <div className="col">
                  <h1>{this.props.match.params.county}</h1>
                </div>
                <div className="col">
                  <Typography className={classes.subtext} color="textSecondary">
                    County Data
                </Typography>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <AppBar position="static">
                <Tabs value={value} onChange={this.handleChange} scrollable scrollButtons="auto">
                  <Tab className="tablabel" label="Monthly Revenue and RevPAR" />
                  <Tab className="tablabel" label="Monthly Revenue By City" />
                </Tabs>
              </AppBar>
              {value === 0 && <TabContainer>{chartdisplay}</TabContainer>}
              {value === 1 && <TabContainer>{chartdisplaycity}</TabContainer>}
            </Paper>
          </Grid>
          <Grid item xs={12} className="tablehead1">
            <CSVLink data={this.state.newposts} className="csvdownload" filename="CountyMonthlyRevenue.csv">
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>
            <MaterialTable
              columns={[
                { title: 'Year', field: "year", type: 'numeric', defaultSort: 'desc' },
                {
                  title: 'January', field: "january", type: 'html',
                  render: rowData => { if (rowData.january === undefined) { rowData.january = 0 }; return (<div>$&nbsp;{rowData.january.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'February', field: 'february', type: 'html',
                  render: rowData => { if (rowData.february === undefined) { rowData.february = 0 }; return (<div>$&nbsp;{rowData.february.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'March', field: 'march', type: 'html',
                  render: rowData => { if (rowData.march === undefined) { rowData.march = 0 }; return (<div>$&nbsp;{rowData.march.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'April', field: 'april', type: 'html',
                  render: rowData => { if (rowData.april === undefined) { rowData.april = 0 }; return (<div>$&nbsp;{rowData.april.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'May', field: 'may', type: 'html',
                  render: rowData => { if (rowData.may === undefined) { rowData.may = 0 }; return (<div>$&nbsp;{rowData.may.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'June', field: 'june', type: 'html',
                  render: rowData => { if (rowData.june === undefined) { rowData.june = 0 }; return (<div>$&nbsp;{rowData.june.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'July', field: 'july', type: 'html',
                  render: rowData => { if (rowData.july === undefined) { rowData.july = 0 }; return (<div>$&nbsp;{rowData.july.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'August', field: 'august', type: 'html',
                  render: rowData => { if (rowData.august === undefined) { rowData.august = 0 }; return (<div>$&nbsp;{rowData.august.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'September', field: 'september', type: 'html',
                  render: rowData => { if (rowData.september === undefined) { rowData.september = 0 }; return (<div>$&nbsp;{rowData.september.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'October', field: 'october', type: 'html',
                  render: rowData => { if (rowData.october === undefined) { rowData.october = 0 }; return (<div>$&nbsp;{rowData.october.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'November', field: 'november', type: 'html',
                  render: rowData => { if (rowData.november === undefined) { rowData.november = 0 }; return (<div>$&nbsp;{rowData.november.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'December', field: 'december', type: 'html',
                  render: rowData => { if (rowData.december === undefined) { rowData.december = 0 }; return (<div>$&nbsp;{rowData.december.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
              ]}
              data={this.state.newposts}
              title={`Monthly Performance: ${this.props.match.params.county}`}
              options={{
                columnsButton: true

              }}
            />

          </Grid>

          <Grid item xs={12} className="tablehead1">
            <CSVLink data={this.state.cityposts} className="csvdownload" filename="CountyByCity.csv">
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>
            <MaterialTable
              columns={[
                { title: 'City', field: 'location_city' },
                { title: 'Year', field: 'year', type: 'numeric', defaultSort: 'desc' },
                { title: 'Month', field: 'month' },
                {
                  title: 'Total Revenue', field: 'Total_Revenue', type: 'html',
                  render: rowData => { return (<div>$&nbsp;{rowData.Total_Revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                { title: 'Total Rooms', field: 'Total_Number_of_Rooms' },
                {
                  title: 'RevPar', field: 'RevPar', type: 'html',
                  render: rowData => { return (<div>$&nbsp;{rowData.RevPar}</div>) }
                },
              ]}
              data={this.state.cityposts}
              title={`By City: ${this.props.match.params.county}`}
              options={{
                columnsButton: true

              }}
            />

          </Grid>
          <Grid item xs={12} className="tablehead1">
            <CSVLink data={this.state.yearposts} className="csvdownload" filename="CountyYearlyRevenue.csv">
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>
            <MaterialTable
              columns={[
                { title: 'Year', field: 'year', type: 'numeric', defaultSort: 'desc' },
                {
                  title: 'Total Revenue', field: 'Year_Revenue', type: 'html', ordering: 'desc',
                  render: rowData => { return (<div>$&nbsp;{rowData.total_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
                {
                  title: 'Change in Revenue', field: 'totalRevChange',

                  render: rowData => {

                    if (rowData.totalRevChange.includes("-")) {
                      return (
                        <Grid container>
                          <Grid item xs={11} sm={5}>
                            {rowData.totalRevChange}
                          </Grid>
                          <Grid item xs={1} sm={7}>
                            <Icon className={classes.Red}>arrow_downward</Icon>
                          </Grid>
                        </Grid>
                      )
                    } else if (rowData.totalRevChange !== "$0 (0%)") {
                      return (
                        <Grid container>
                          <Grid item xs={11} sm={5}>
                            {rowData.totalRevChange}
                          </Grid>
                          <Grid item xs={1} sm={7}>
                            <Icon className={classes.Green}>arrow_upward</Icon>
                          </Grid>
                        </Grid>
                      )
                    }
                    else return (
                      <Grid container>
                        <Grid item xs={11} sm={5}>
                          {rowData.totalRevChange}
                        </Grid>
                        <Grid item xs={1} sm={7}>
                          <Icon className={classes.Grey}>minimize</Icon>
                        </Grid>

                      </Grid>
                    )


                  },
                  type: 'html'
                },

              ]}
              data={this.state.yearposts}
              title={`Yearly Data: ${this.props.match.params.county}`}
              options={{
                columnsButton: true
              }}
            />
          </Grid>
          <Grid item xs={12} className="tablehead">

            <MaterialTable
              columns={[
                { title: 'Year', field: 'year', type: 'numeric', defaultSort: 'desc' },
                { title: 'Month', field: 'month', type: 'numeric' },
                { title: 'Rooms', field: 'Total_Number_of_Rooms', type: 'numeric' },
                {
                  title: 'Revenue', field: 'Total_Revenue', type: 'html',
                  render: rowData => { return (<div>$&nbsp;{rowData.Total_Revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                },
              ]}
              data={this.state.rawposts}
              title={`Complete Data Set: ${this.props.match.params.county}`}
              options={{
                columnsButton: true,
                exportButton: true,
                ordering: 'desc'
              }}
            />
          </Grid>
        </Grid>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </div>



    );
  }
}
County.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(County)
