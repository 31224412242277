export const filters = [
    {
      value: "monthly",
      label: "Lodging Monthly"
    },
    {
      value: "quarterly",
      label: "Lodging Quarterly"
    },
    {
      value: "quarterlymonthly",
      label: "Lodging Monthly + Quarterly"
    },
    {
      value: "mbt",
      label: "Mixed Beverage Monthly"
    }
  
  ];
  
  // Months
  export const months= [
  {
   value: "1",
   label: "January"
  },
  {
   value: "2",
   label: "February"
  },
  {
   value: "3",
   label: "March"
  },
  
  {
   value: "4",
   label: "April"
  },
  {
   value: "5",
   label: "May"
  },
  {
   value: "6",
   label: "June"
  },
  {
   value: "7",
   label: "July"
  },
  {
   value: "8",
   label: "August"
  },
  {
   value: "9",
   label: "September"
  },
  {
   value: "10",
   label: "October"
  },
  {
   value: "11",
   label: "November"
  },
  {
   value: "12",
   label: "December"
  },
  
  ]
  // Quarters of the Year
  export const quarters= [
  {
   value: "1",
   label: "1"
  },
  {
   value: "2",
   label: "2"
  },
  {
   value: "3",
   label: "3"
  },
  
  {
   value: "4",
   label: "4"
  }
  ]
  
  // Year
  export const years= [
  {
   value: "2019",
   label: "2019"
  },
  {
   value: "2018",
   label: "2018"
  },
  {
   value: "2017",
   label: "2017"
  },
  {
   value: "2016",
   label: "2016"
  },
  
  {
   value: "2015",
   label: "2015"
  },
  {
   value: "2014",
   label: "2014"
  },
  {
   value: "2013",
   label: "2013"
  },
  {
   value: "2012",
   label: "2012"
  },
  
  {
   value: "2011",
   label: "2011"
  },
  {
   value: "2010",
   label: "2010"
  },
  {
   value: "2009",
   label: "2009"
  },
  {
   value: "2008",
   label: "2008"
  },
  
  {
   value: "2007",
   label: "2007"
  },
  {
   value: "2006",
   label: "2006"
  },
  {
   value: "2005",
   label: "2005"
  },
  {
   value: "2004",
   label: "2004"
  },
  
  {
   value: "2003",
   label: "2003"
  },
  {
   value: "2002",
   label: "2002"
  },
  {
   value: "2001",
   label: "2001"
  },
  
  {
   value: "2000",
   label: "2000"
  },
  ]
  
  // Search options
  export const searches= [
  {
   value: "city",
   label: "City"
  },
  {
   value: "county",
   label: "County"
  },
  {
   value: "zip",
   label: "Zip Code"
  }
  ]