/* eslint-disable no-new-object */
import React, { Component } from 'react';
import Downshift from "downshift";
//import deburr from 'lodash/deburr';
import debounce from 'debounce-promise';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    root: {
        flexGrow: 1,
        overflow: 'hidden',

    },
    paper: {
        position: 'absolute',
        zIndex: 3,
        left: 0,
        right: 0,

    },
    menuitem: {
        padding: 18,
        height: 24,
        fontSize: 25,
        flexGrow: 1,

        // display: 'flex',
        [theme.breakpoints.down('sm')]: {
            //flexGrow: 1,
            fontSize: 22,
            height: '6vh',
            overFlow: 'hidden',
            placeItem: 'center',
            verticalAlign: 'middle'
        },

    },
    input: {
        display: 'flex',
        padding: 0,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 10,
    },
    link: {
        color: "",
        ":hover": {
            textDecoration: "none",

        },
    },
    divider: {
        zIndex: 3,
    },
    button: {
        color: 'white',
        margin: theme.spacing.unit,
    },
    dialog: {
        marginTop: 0,
        marginBottom: 500
    },
    typography2: {
        marginTop: 0,
        padding: 0,
        fontSize: '66%',
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: 14,
        //     display: 'block',
        //     overFlow: 'hidden',
        //     whiteSpace: 'nowrap',
        //     textOverflow: 'ellipsis',
        //     width: '100%',
        // },
        // [theme.breakpoints.down('xs')]: {
        //     fontSize: 14,
        //     display: 'block',
        //     overFlow: 'hidden',
        //     whiteSpace: 'nowrap',
        //     textOverflow: 'ellipsis',
        //     width: 260,
        // },
    },
    typography: {
        margin: 0,
        padding: 0,
    },
    typography3: {
        marginTop: 0,
        padding: 0,
        fontSize: '70%',
        // [theme.breakpoints.down('xs')]: {
        //     fontSize: '.5rem',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: '1rem',
        // },

    },
    typography4: {
        marginTop: 0,
        padding: 0,
        fontSize: '1rem',
        // [theme.breakpoints.down('xs')]: {
        //     fontSize: '.8rem',  
        // },
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: '1rem',
        // },

    },
    bolded: {
        fontWeight: 'bold',
        color: '#242a33',
    },
    breakpoint: {
        // [theme.breakpoints.down('sm')]: {
        //     display: 'block'

        // },
    },
    icon: {
        paddingBotthom: 40
    },
    chip: {
        fontSize: '.7rem',
        fontWeight: 'bold',
        padding: '.1rem .2rem',
        borderRadius: '20px',
        backgroundColor: '#f44336',
        color: 'white'

    },
    gridRow: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'flex-start',

    },
    respDate: {
        fontSize: '.7rem',
        color: 'red'
    },
    displayBreak: {
        [theme.breakpoints.down('sm')]: {
            display: 'block'

        },
    }

})


function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
        <TextField
            InputProps={{
                inputRef: ref,
                ...InputProps,
            }}
            {...other}
        />
    );
}

const makeRequestCreator = () => {
    let call;
    return url => {
      if (call) {
        call.cancel("Only one request allowed at a time.");
        //console.log("call", call);
      }
      call = axios.CancelToken.source();
      return axios.get(url, {
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('token') //the token is a variable which holds the token
        },
        //cancelToken: this.axiosCancelSource.token
        cancelToken: call.token
      });
    };
   
  };


const get = makeRequestCreator();

class SingleSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            optionsStore: {
                filter2: 'hotel',
            },
            selectedItem: '',
            filter2: 'hotel',
            type: 'monthly',
            showMonth: true,
            errormsg1: false,
            errormsg2: false,
            errormsg3: false,
            inputValue: '',
            query: '',
            open: false,
            latestYear: '',
            latestMonth: '',
            defaultOption: '',
            urlHotelSearch: 'hotelinfo',
            type2: 0,
            noResults: false,
            filter3: 'all',
            isSearching: false

        }
        this.getOptions = debounce(this.getOptions.bind(this), 350);
        this.onInputChange = this.onInputChange.bind(this);
        this.downshiftOnChange = this.downshiftOnChange.bind(this);

    }

    componentDidMount() {


        //this.axiosCancelSource = axios.CancelToken.source()

    }


    onInputChange = (e) => {
        try {

            let inputValue = e.target.value;
            let filteredValue = inputValue.replace(/[^0-9a-z ']/gi, '');
            this.setState({ inputValue: filteredValue });
            this.getOptions(filteredValue);

        } catch(err){
            handleError(err, pathname)
        }
      
    }

    checkType(type) {
        //console.log(type);
        try {
            if (type === "mbt") {
                this.setState({ type: "mbt" });
                this.setState({ urlHotelSearch: "mbthotelinfo" });
                this.setState({ type2: 1 });
            } else if (type === "hotel") {
                this.setState({ type: "monthly" });
                this.setState({ urlHotelSearch: "hotelinfo" });
                this.setState({ type2: 0 });
            }

        } catch(err){
            handleError(err, pathname)
        }
       
    }




    // Takes in the name and set's the state of the selected elements name
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
        this.checkType(event.target.value);
    };


    // for info button
    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };


    downshiftOnChange(inputValue) {

       
        this.setState({ selectedItem: inputValue });


        // first check if 'property' was checked
        try {
            if (inputValue.location_name) {
                this.props.history.push(`/stt/${this.state.urlHotelSearch}/${inputValue.location_name.trim().replace('/', "(slash)").replace('#', "(hash)")}/${inputValue.taxpayer_number}/${inputValue.location_number}`);

            } else if (inputValue.zipcodes) {
                this.props.history.push({ pathname: `/stt/results/${this.state.type}/zip/${inputValue.zipcodes}` });
            } else if (inputValue.cities) {
                this.props.history.push({ pathname: `/stt/results/${this.state.type}/city/${inputValue.cities}` })
            } else if (inputValue.counties) {
                this.props.history.push({ pathname: `/stt/results/${this.state.type}/county/${inputValue.counties}` });
            }
        } catch(err){
            handleError(err, pathname)
        }
    }



    mapOptionsToValues = options => {

        let firstOption = [];
        let mergedOptions;

        try {

            if (options.zipcodes.length > 0) {
                firstOption.push({ "zipcodes": options.zipcodes })
            }
            if (options.cities.length > 0) {
                firstOption.push({ "cities": options.cities })
            }
            if (options.counties.length > 0) {
                firstOption.push({ "counties": options.counties })
            }
    
            //console.log(firstOption);
            if (firstOption.length === 0) {
                this.setState({ noResults: true })
                this.setState({ isSearching: false })
            }
            // push all properties
            firstOption.push(options.properties);
            mergedOptions = [].concat.apply([], firstOption);
            //mergedOptions = [mergedOptions, ...firstOption];
            this.setState({ options: mergedOptions });
    
            return mergedOptions;

        } catch(err){
            handleError(err, pathname)
        }

    }



    async getOptions(inputValue) {

        //this.axiosCancelSource.cancel('Component unmounted.')

        const minimumInput = 3;
        //const noResults = -1;

        if (inputValue.trim().length < minimumInput && this.state.inputValue.length < minimumInput) {
            this.setState({ 
                options: [],
                noResults: false,
                isSearching: false
            });
        } else {

            this.setState({ isSearching: true })

            try {
                this.setState({ 
                    errormsg1: false,
                    errormsg2: false,
                    errormsg3: false
                })
                const res = await get(`${apiUrl}/rest/search/${this.state.filter2}/${this.state.filter3}/${inputValue}/`);
                this.mapOptionsToValues(res.data);
                this.setState({ isSearching: false });
            } catch(err){
                if(err.config){
                    handleError(err, pathname)
                    this.setState({ errormsg3: true });
                }
            }
        }
    }


    render() {

        let errormsg1;
        if (this.state.errormsg1 === true) {
            errormsg1 = (
                <span className="errormsg">Input too short. Please Enter full property Name, Zip, or City.</span>
            )
        }

        let errormsg2;
        if (this.state.errormsg2 === true) {
            errormsg2 = (
                <span className="errormsg">Input field must not be blank.</span>
            )
        }

        let errormsg3;
        if (this.state.errormsg3 === true) {
            errormsg2 = (
                <span className="errormsg">Could not get response from server.</span>
            )
        }

        // styling
        const { classes } = this.props;
        // state variables
        const { selectedItem, options } = this.state;

        return (
                <Grid item xs={12}>
                    <Paper className="search_paper">
                        <div className={classes.gridRow}>
                            <span> <Typography 
                                        variant="h5" 
                                        component="h3" 
                                        style={{ position: 'relative', marginRight: 5 }}
                                    >
                                    Speed Search
                                </Typography> </span>
                            <span className="beta-feature-badge">BETA</span>
                        </div>
                                <Typography variant="subtitle1" style={{ display: 'inline' }}>
                                    <strong>First select an option:</strong>
                                </Typography>
                                <span className={classes.displayBreak}>
                                    <span>
                                        <Radio
                                            checked={this.state.filter2 === "hotel"}
                                            onChange={this.handleChange("filter2")}
                                            value="hotel"
                                            name="hotel"
                                            aria-label="Lodging"
                                        />
                                    </span>
                                    <label htmlFor="hotel">Lodging</label>
                                    <span>
                                        <Radio
                                            checked={this.state.filter2 === "mbt"}
                                            onChange={this.handleChange("filter2")}
                                            value="mbt"
                                            name="mbtName"
                                            aria-label="MBT"

                                        />
                                    </span>
                                    <label htmlFor="mbtName">Alcohol</label>
                                </span>
                            {/* </Grid> */}
                            <Grid item xs={12} >
                                <Downshift
                                    onChange={this.downshiftOnChange}
                                    itemToString={item => (item ? item.location_name || item.zip || item.city || item.county : "")}
                                    selectedItem={selectedItem}
                                    defaultHighlightedIndex={0}
                                    inputValue={this.state.inputValue}
                                // isOpen={true}
                                >
                                    {({
                                        selectedItem,
                                        getInputProps,
                                        getItemProps,
                                        isOpen,
                                        highlightedIndex,
                                    }) => (
                                            // <div {...getMenuProps()}>
                                            <div className="uni-select">
                                                {renderInput({
                                                    fullWidth: true,
                                                    variant: 'outlined',
                                                    error: this.state.errormsg2 || this.state.errormsg1,
                                                    classes,
                                                    InputProps: getInputProps({
                                                        // value: this.state.selectedItem,
                                                        onChange: this.onInputChange,
                                                        onKeyDown: event => {
                                                            if (event.key === 'Enter') {
                                                                ;
                                                                if (this.state.inputValue.trim() === '') {
                                                                    this.setState({ errormsg2: true });
                                                                } else if (this.state.inputValue.trim().length < 3) {
                                                                    this.setState({ errormsg1: true });
                                                                }
                                                            }
                                                        }
                                                    }),
                                                    label: 'Start typing a name, ZIP, city, or county'
                                                })}
                                                {isOpen ? (
                                                    <Grid container wrap="nowrap">
                                                        <Paper className={classes.paper} square>
                                                            {this.state.isSearching === true ?
                                                                <MenuItem>
                                                                    <Typography>Searching...</Typography>
                                                                </MenuItem> :
                                                                options.slice(0, 10).map((item, index) => (
                                                                        <React.Fragment>
                                                                            <Grid item xs={12}>
                                                                                <MenuItem 
                                                                                button
                                                                                selected={highlightedIndex === index}
                                                                                {...getItemProps({ key: index, item })}
                                                                                key={index}
                                                                                >
                                                                                    {item.zipcodes ?
                                                                                        <Typography> 
                                                                                            Search all properties in '<span className={classes.bolded}>{item.zipcodes}</span>' ?
                                                                                        </Typography> : 
                                                                                        null
                                                                                        }
                                                                                    {item.cities ? 
                                                                                        <Typography>
                                                                                            Search all properties in City of <span className={classes.breakpoint}>'<span className={classes.bolded} >{item.cities}</span>'?</span><span className="fixed-element"></span>
                                                                                        </Typography> :                                                                                         
                                                                                        null
                                                                                        }
                                                                                    {item.location_name ?
                                                                                        <div style={{ padding: 0, margin: 0 }}>
                                                                                            <Typography className={classes.typography}>
                                                                                                <span className={classes.breakword}>   
                                                                                                    <strong>
                                                                                                        {item.location_name}
                                                                                                    </strong>
                                                                                                </span> 
                                                                                            </Typography>
                                                                                            <Typography className={classes.typography3}>
                                                                                                {item.address},
                                                                                                <span className={classes.breakpoint}><span> {item.city},</span><span> {item.state}</span><span> {item.zip} - {item.status === "1" ?
                                                                                                <span className={classes.breakpoint}><span style={{ color: 'green' }}> Current </span></span> :
                                                                                                <span className={classes.breakpoint}><span style={{ color: 'red' }}> Permit Ended </span><span className={classes.respDate}><strong> ({item.responsibility_end_date})</strong></span> </span>}</span></span>
                                                                                            </Typography>
                                                                                        </div>
                                                                                        : null}
                                                                                    {item.counties ? 
                                                                                        <Typography>
                                                                                            Search all properties in County of <span className={classes.breakpoint}>'<span className={classes.bolded}>{item.counties}</span>'?</span>
                                                                                            </Typography> 
                                                                                            : null
                                                                                            }
                                                                                </MenuItem>
                                                                                <Divider className={classes.divider} />
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    ))
                                                            }
                                                            {this.state.noResults === true && options.length === 0 ? 
                                                            <MenuItem>
                                                            <Typography 
                                                                variant="h6" 
                                                                nowrap 
                                                                className={classes.typography4}>
                                                                    No Results Found.
                                                            </Typography>
                                                                </MenuItem> : null}
                                                        </Paper>
                                                    </Grid>
                                                ) : null}
                                            </div>
                                        )}
                                </Downshift>
                                <p>{errormsg2}</p>
                                <p>{errormsg1}</p>
                                <p>{errormsg3}</p>
                            </Grid>
                    </Paper>
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                    className={classes.dialog}
                >
                    <DialogContent>
                        <DialogContentText>
                            We can possibly put additional info?
                        </DialogContentText>
                    </DialogContent>
                    <Button onClick={this.handleClose} color="primary" autoFocus>
                        Got it.
                    </Button>
                </Dialog>
                </Grid >
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(SingleSearch));