import axios from "axios";
import { apiUrl } from "../helpers";

// const apiUrl = `https://api.searchtexastax.com/rest/basic`;
let headers = {
  headers: {
    authorization: "Bearer " + localStorage.getItem("token"),
  },
};
// const data = {
//   data: {
//     taxpayer_number: taxPayerNumber,
//     location_number: locationNum
//   },
// }

export default {
  getOneHotelLatLong: (taxPayerNum, locationNum) =>
    axios.get(
      `${apiUrl}/rest/basic/map/${taxPayerNum}/${locationNum}`,
      headers
    ),

  getHotelInfo: () => axios.get(``, headers),

  getHotelFinanceRecordMonthly: (hotelName, taxPayerNum, locationNum, cancel) =>
    axios.get(
      `${apiUrl}/rest/basic/${hotelName
        .replace("/", "(slash)")
        .replace("#", "(hash)")
        .trim()}/${taxPayerNum}/${locationNum}`,
      headers,
      `cancelToken: ${cancel}`
    ),

  getHotelFinanceRecordYearly: (
    hotelName,
    taxPayerNum,
    locationNum,
    filerType,
    cancel
  ) =>
    axios.get(
      `${apiUrl}/rest/basic/testyearlyvalues/${hotelName}/${taxPayerNum}/${locationNum}/${filerType}`,
      headers,
      `cancelToken:${cancel}`
    ),

  getHotelFinanceRecordYearlyMBT: (taxPayerNum, locationNum) =>
    axios.get(
      `${apiUrl}/rest/mbt/testyearlyvalues/${taxPayerNum}/${locationNum}`,
      headers
    ),

  getHotelFinanceRecordLastTwelveMonth: (
    yearlyPosts,
    hotelName,
    taxPayerNum,
    locationNum,
    filerType
  ) =>
    axios.get(
      `${apiUrl}/rest/basic/testlastyearlyvalues/${yearlyPosts}/${hotelName}/${taxPayerNum}/${locationNum}/${filerType}`,
      headers
    ),

  // not implemented line ~969
  // getHotelFinanceRecordLastTwelveMonth: function(yearlyPosts, hotelName, taxPayerNum, locationNum, filerType, cancel) {
  //   return axios.get(`${apiUrl}/rest/basic/testlastyearlyvalues/${yearlyPosts}/${hotelName}/${taxPayerNum}/${locationNum}/${filerType}`,
  //   {
  //     headers: {
  //       authorization: 'Bearer ' + localStorage.getItem('token'),
  //     },
  //     cancelToken: cancel
  //   })
  // },

  postHotelHistory: function(taxPayerNumber, locationNum, cancel) {
    return axios({
      method: "post",
      url: `${apiUrl}/rest/basic/hotelhistory`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: {
        taxpayer_number: taxPayerNumber,
        location_number: locationNum,
      },
      cancelToken: cancel,
      //data: {
      //   location_address: this.state.postsaddress,
      //   location_number: this.props.match.params.locationnumber,
      //   location_city: this.state.postscity
      // }
    });
  },

  // postHotelHistory: (taxPayerNumber, locationNum, cancel) =>
  //   axios.post(`${apiUrl}/hotelhistory`,
  //   headers, data,
  //   `cancelToken: ${cancel}`

  //   )
};
