/* eslint-disable no-new-object */
import React from 'react'
import axios from 'axios'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper';
import { Chart } from "react-google-charts";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { CSVLink } from "react-csv";
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  }
});

const durations = [
  {
    value: "1",
    label: "1 year"
  },
  {
    value: "3",
    label: "3 years"
  },
  {
    value: "5",
    label: "5 years"
  },
  {
    value: "10",
    label: "10 years"
  },
  {
    value: "100",
    label: "All"
  }
]
class BrandParent extends React.Component {
  state = {
    posts: [],
    rawposts: [],
    newposts: [],
    brandposts: [],
    token: '',
    dataLoadingStatus: 'loading',
    chartData: [],
    value: 0,
    duration: "1",
    labelWidth: 0,
    age: ''
  }
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  }
  handleChange1 = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    axios({
      method: 'post',
      url: `${apiUrl}/rest/brands/testbrandparent`,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json',//the token is a variable which holds the token
      },
      data: { parentId: this.props.match.params.parentId }
    })
      .then(res => {
        //const [metadata, data] = res.data

        const columns = [
          { type: 'date', label: 'Month' },
          { type: 'number', label: 'Total Revenue' },
          { type: 'number', label: 'RevPar' },
        ]
        let rows = []
        var currentd = new Date();
        var currentyear = currentd.getFullYear();
        for (let row of res.data) {
          let { date, RevPar } = row
          if (row.year >= (currentyear - event.target.value) && row.year <= currentyear) {
            date = new Date(row.year + "-" + row.month);
            ////console.log(date);
            date = new Date(row.year, row.month - 1, 1);
            rows.push([date, row.sum_room_receipts, RevPar])
            ////console.log(rows);
          }


        }
        //////console.log(columns);
        //////console.log(columns.length);
        //////console.log(rows);
        this.setState({
          chartData: [columns, ...rows],
          dataLoadingStatus: 'ready',
        })

      }).catch(e => {
        handleError(e, pathname);
        //window.location.replace('/login');
        ////console.log(e);
      });
  }
  componentDidMount() {
    document.title = "Parent Brand - Search Texas Tax";


    axios({
      method: 'post',
      url: `${apiUrl}/rest/brands/testbrandparent`,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json',//the token is a variable which holds the token
      },
      data: { parentId: this.props.match.params.parentId }
    })
      .then(res => {
        //console.log(res.data);
        //const [metadata, data] = res.data

        const columns = [
          { type: 'date', label: 'Month' },
          { type: 'number', label: 'Total Revenue' },
          { type: 'number', label: 'RevPar' },
        ]
        let rows = []
        var currentd = new Date();
        var currentyear = currentd.getFullYear();
        for (let row of res.data) {
          let { date, RevPar } = row
          if (row.year >= (currentyear - 1) && row.year <= currentyear) {
            date = new Date(row.year + "-" + row.month);
            ////console.log(date);
            date = new Date(row.year, row.month - 1, 1);
            rows.push([date, row.sum_room_receipts, RevPar])
            ////console.log(rows);
          }


        }
        //////console.log(columns);
        //////console.log(columns.length);
        //////console.log(rows);
        this.setState({
          chartData: [columns, ...rows],
          dataLoadingStatus: 'ready',
        })
        const posts = res.data;

        this.setState({ posts: posts });
        var i = 0;
        var j = 0;
        var a = 0;

        var newposts = [];
        for (i = 0; i < posts.length; i++) {


          if (a === 0) {

            newposts.push(new Object());
            newposts[newposts.length - 1].year = posts[i].year;
            if (posts[i].month === 1) {
              newposts[newposts.length - 1].january = posts[i].sum_room_receipts;
              ////////console.log(temp['January']);
            }
            if (posts[i].month === 2) {
              newposts[newposts.length - 1].february = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 3) {
              newposts[newposts.length - 1].march = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 4) {
              newposts[newposts.length - 1].april = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 5) {
              newposts[newposts.length - 1].may = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 6) {
              newposts[newposts.length - 1].june = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 7) {
              newposts[newposts.length - 1].july = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 8) {
              newposts[newposts.length - 1].august = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 9) {
              newposts[newposts.length - 1].september = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 10) {
              newposts[newposts.length - 1].october = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 11) {
              newposts[newposts.length - 1].november = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 12) {
              newposts[newposts.length - 1].december = posts[i].sum_room_receipts;
            }
            // //////console.log("Inside a");
            //newposts.push(temp);
            a = 1;
            continue;
          }

          for (j = 0; j < newposts.length; j++) {
            if (newposts[j].year === posts[i].year) {

              if (posts[i].month === 1) {
                newposts[j].january = posts[i].sum_room_receipts;
                ////////console.log(temp['January']);
              }
              if (posts[i].month === 2) {
                newposts[j].february = posts[i].sum_room_receipts;
                ////////console.log(newposts[j]);
              }
              if (posts[i].month === 3) {
                newposts[j].march = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 4) {
                newposts[j].april = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 5) {
                newposts[j].may = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 6) {
                newposts[j].june = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 7) {
                newposts[j].july = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 8) {
                newposts[j].august = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 9) {
                newposts[j].september = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 10) {
                newposts[j].october = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 11) {
                newposts[j].november = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 12) {
                newposts[j].december = posts[i].sum_room_receipts;
                a = 0;
              }
              break;

            }
          }

        }

        this.setState({ newposts: newposts });

        //Brand Insight Individual hotels

        axios({
          method: 'post',
          url: `${apiUrl}/rest/brands/brandparenthotels`,
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',//the token is a variable which holds the token
          },
          data: { parentId: this.props.match.params.parentId }
        })
          .then(res => {
            const brandposts = res.data;
            // console.log(brandposts);
            this.setState({ brandposts: brandposts });
          }).catch(e => {
            handleError(e, pathname);
            //window.location.replace('/login');
          });


      }).catch(e => {
        handleError(e, pathname);
        //window.location.replace('/login');
      });

  }

  render() {
    const { classes } = this.props;
    let chartdisplay;
    if (this.state.dataLoadingStatus === "ready") {
      chartdisplay = (
        <div>
          <TextField
            id="outlined-select-currency"
            select

            className={classes.textField}
            value={this.state.duration}
            onChange={this.handleChange1('duration')}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}

            margin="normal"

          >
            {durations.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <form className={classes.container} noValidate autoComplete="off">




          </form>
          <Chart
            chartType="Line"
            loader={<div>Loading Chart</div>}
            width={'100%'}
            height={'400px'}
            data={this.state.chartData}
            options={{
              chartArea: { height: '100%', width: '90%' },
              hAxis: {
                format: 'yyyy',


              },
              vAxis: {
                format: 'short'
              },
              title: 'Debt incurred over time.',
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: 'sum_room_receipts' },
                1: { axis: 'Number_Of_Rooms_Available' }
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  sum_room_receipts: { label: 'Monthly Revenue' },
                  all: {
                    format: {
                      pattern: 'currency'
                    }


                  }
                },
                x: {

                  all: {
                    format: {
                      pattern: "MMM dd, yyyy"
                    }


                  }
                }

              }
            }}
            rootProps={{ 'data-testid': '2' }}
          />
        </div>
      )
    }
    else {
      chartdisplay = (
        <div className="loadingparent"> <CircularProgress className="loading" size={50} /></div>
      )
    }

    const { value } = this.state;


    return (


      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="data_title">
            <div className="row">
              <div className="col">
                <h1>{this.props.match.params.parent}</h1>
              </div>
              <div className="col">
                <Typography className={classes.subtext} color="textSecondary">
                  Hotel Occupancy Tax Receipts
         </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className="graph-tabs">
            <AppBar position="static">
              <Tabs className="parenttab" value={value} onChange={this.handleChange} scrollable scrollButtons="auto">
                <Tab className="tablabel" label="Monthly Revenue and RevPAR Comparison " />

              </Tabs>
            </AppBar>
            {value === 0 && <TabContainer>{chartdisplay}</TabContainer>}
          </Paper>
        </Grid>
        <Grid item xs={12} className="tablehead1">
          <CSVLink data={this.state.posts} className="csvdownload" filename="ParentBrandMonthlyRevenue.csv">
            <Button className="csvbutton" title="Export As CSV">
              <Icon className="csvicon">save_alt</Icon>
            </Button>
          </CSVLink>
          <MaterialTable
            columns={[
              { title: 'Year', field: "year", type: 'numeric', defaultSort: 'desc' },
              {
                title: 'January', field: "january", type: 'html',
                render: rowData => { if (rowData.january === undefined) { rowData.january = 0 }; return (<div>$&nbsp;{rowData.january.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'February', field: 'february', type: 'html',
                render: rowData => { if (rowData.february === undefined) { rowData.february = 0 }; return (<div>$ {rowData.february.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'March', field: 'march', type: 'html',
                render: rowData => { if (rowData.march === undefined) { rowData.march = 0 }; return (<div>$ {rowData.march.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'April', field: 'april', type: 'html',
                render: rowData => { if (rowData.april === undefined) { rowData.april = 0 }; return (<div>$ {rowData.april.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'May', field: 'may', type: 'html',
                render: rowData => { if (rowData.may === undefined) { rowData.may = 0 }; return (<div>$ {rowData.may.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'June', field: 'june', type: 'html',
                render: rowData => { if (rowData.june === undefined) { rowData.june = 0 }; return (<div>$ {rowData.june.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'July', field: 'july', type: 'html',
                render: rowData => { if (rowData.july === undefined) { rowData.july = 0 }; return (<div>$ {rowData.july.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'August', field: 'august', type: 'html',
                render: rowData => { if (rowData.august === undefined) { rowData.august = 0 }; return (<div>$ {rowData.august.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'September', field: 'september', type: 'html',
                render: rowData => { if (rowData.september === undefined) { rowData.september = 0 }; return (<div>$ {rowData.september.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'October', field: 'october', type: 'html',
                render: rowData => { if (rowData.october === undefined) { rowData.october = 0 }; return (<div>$ {rowData.october.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'November', field: 'november', type: 'html',
                render: rowData => { if (rowData.november === undefined) { rowData.november = 0 }; return (<div>$ {rowData.november.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'December', field: 'december', type: 'html',
                render: rowData => { if (rowData.december === undefined) { rowData.december = 0 }; return (<div>$ {rowData.december.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
            ]}
            data={this.state.newposts}
            title={`Monthly Performance: ${this.props.match.params.parent}`}
            options={{
              columnsButton: true

            }}
          />

        </Grid>
        <Grid item xs={12} className="tablehead1">
          <CSVLink data={this.state.posts} className="csvdownload" filename="ParentBrandCompleteData.csv">
            <Button className="csvbutton" title="Export As CSV">
              <Icon className="csvicon">save_alt</Icon>
            </Button>
          </CSVLink>
          <MaterialTable
            columns={[
              { title: 'Year', field: 'year', type: 'numeric', defaultSort: 'desc' },
              { title: 'Month', field: 'month', type: 'numeric' },
              { title: 'Total Number of Rooms', field: 'sum_room_capacity', type: 'numeric' },
              {
                title: 'Total Revenue', field: 'sum_room_receipts', type: 'html',
                render: rowData => { return (<div>$ {rowData.sum_room_receipts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>)}
              }
            ]}
            data={this.state.posts}
            title={`Complete Data Set: ${this.props.match.params.parent}`}
            options={{
              columnsButton: true

            }}
          />
        </Grid>


        <Grid item xs={12} className="tablehead1">
          <CSVLink data={this.state.brandposts} className="csvdownload" filename="BrandInsightParentData.csv">
            <Button className="csvbutton" title="Export As CSV">
              <Icon className="csvicon">save_alt</Icon>
            </Button>
          </CSVLink>
          <MaterialTable
            columns={[
              {
                title: 'Hotel Name', field: 'location_name',
                render: rowData => {
                  return (<Link to={`/stt/hotelinfo/${rowData.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${rowData.taxpayer_number}/${rowData.location_number}`} onClick={() => window.scrollTo(0, 0)}>
                    {rowData.location_name}</Link>)
                },
                type: 'html'
              },
              { title: 'Address', field: 'location_address' },
              {
                title: 'City',
                render: rowData => { return (<Link to={`/stt/city/${rowData.location_city}`} onClick={() => window.scrollTo(0, 0)}>{rowData.location_city}</Link>) }
              },
              {
                title: 'County',
                render: rowData => { return (<Link to={`/stt/county/${rowData.verified_county}`} onClick={() => window.scrollTo(0, 0)}>{rowData.verified_county}</Link>) }
              },
              { title: 'State', field: 'location_state' },
              { title: 'Zip Code', field: 'location_zip' },
              { title: 'Year', field: 'year', type: 'numeric', defaultSort: 'desc' },
              { title: 'Month', field: 'month' },
              { title: 'Total Number of Rooms', field: 'room_capacity', type: 'numeric' },
              {
                title: 'Total Revenue', field: 'room_receipts', type: 'html',
                render: rowData => { return (<div>$ {rowData.room_receipts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              {
                title: 'Taxable Revenue', field: 'taxable_receipts', type: 'html',
                render: rowData => { return (<div>$ {rowData.taxable_receipts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
              },
              { title: 'Taxpayer Name', field: 'taxpayer_name' },
              { title: 'Taxpayer Number', field: 'taxpayer_number' },
              { title: 'Taxpayer Address', field: 'taxpayer_address' },
              { title: 'Taxpayer State', field: 'taxpayer_state' }
            ]}
            data={this.state.brandposts}
            title={`Brand Insights: ${this.props.match.params.parent}`}
            options={{
              columnsButton: true

            }}
          />
        </Grid>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Grid>



    );
  }
}
BrandParent.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(BrandParent);
