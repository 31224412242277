import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import { CSVLink } from "react-csv";
import axios from "axios";
import PropTypes from "prop-types";

// Components
import ListTaxPayerProp from "./ListTaxPayerProp";
// import ListHotels from './ListHotels';

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { Chart } from "react-google-charts";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";
import shortid from "shortid";
import uuid from "uuid";
import moment from "moment";
import grey from "@material-ui/core/colors/grey";
import {
  durations,
  durations1,
  durationsttm,
  durationsttmrevpar,
} from "../utils/googleChartHelper";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";
import GoogleMapReact from "./GoogleMap";
// import GoogleMapReact from './GoogleMap';
import Fab from "@material-ui/core/Fab";
import API from "../utils/API";
import { CardContent } from "@material-ui/core";

let pathname = window.location.pathname;

//                   blue,   scarlette,    golden,   green,    purpple,    aqua,   lightorange, booger-green, indigo, blossom-pink, dark-aqua, dark-pink
let chartColors = [
  "#4285f4",
  "#db4437",
  "#f4b400",
  "#0f9d58",
  "#ab47bc",
  "#00acc1",
  "#ff7043",
  "#9e9d24",
  "#5c6bc0",
  "#f06292",
  "#00796b",
  "#c2185b",
];
chartColors = [...chartColors];

const sideBarColor = grey[300];

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const addSpace = num => {
  let str = " ";
  for (let i = 0; i < num; i++) str += " ";
  return str;
};

//function createMarkup() { return (<div>"TEST"</div>) };
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  card: {
    minHeight: "300px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "300px",
      overflow: "auto",
      maxHeight: "300px",
    },
    [theme.breakpoints.up("sm")]: {
      // minHeight: '300px',
      maxHeight: "300px",
      overflow: "auto !important",
    },
  },
  typography: {
    margin: 0,
    padding: 0,
  },
  typography3: {
    marginTop: 0,
    padding: 0,
    fontSize: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: ".5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  listItem: {
    paddingTop: "4px",
    paddingBottom: "4px",
    marginTop: "0px",
    marginBottom: "0px",
  },
  map: {
    [theme.breakpoints.down("md")]: {
      height: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "25vh",
    },
  },
  popover: {
    pointerEvents: "none",
    //padding: '15px!important',
    //margin: '15px!important',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  fab: {
    margin: theme.spacing.unit * 2,
  },
  absolute: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3,
    backgroundColor: "#d50000",
    zIndex: "2000",
    "&:hover": {
      backgroundColor: "#f44336",
    },
  },
  button: {
    textTransform: "Capitalize",
    color: "rgba(0, 0, 0, 0.54)",
  },
  legend: {
    position: "none",
  },
  Red: {
    color: "red",
    top: "5px",
    position: "relative",
    fontSize: "21px!important",
    fontWeight: "1000!important",
  },
  Green: {
    color: "green",
    top: "5px",
    position: "relative",
    fontSize: "21px!important",
    fontWeight: "1000!important",
  },
  Grey: {
    color: "gray",
    position: "relative",
    fontSize: "21px!important",
    marginBottom: "5px",
  },
  avatar: {
    margin: 0,
  },
  pinkAvatar: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#d50000",
    width: 120,
    height: 30,
    borderRadius: 5,
    fontSize: 15,
    textTransform: "Capitalize",
  },
  subtext: {
    textTransform: "Capitalize",
  },
  subtext2: {
    height: 25,
    fontSize: ".9rem",
    paddingTop: 3,
    paddingLeft: 5,
  },
  link: {
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "none",
      color: "#2196f3",
    },
  },
  propertyLink: {
    color: "black",
    "&:hover": {
      textDecoration: "none",
      color: "#1e88e5",
    },
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  divider: {
    marginTop: 10,
    zIndex: 3,
  },
  mbt: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#0d47a1",
    borderRadius: 5,
    fontSize: ".8rem",
    padding: ".5px 2px",
  },
  mbtDot: {
    height: 10,
    width: 10,
    paddingRight: 5,
    backgroundColor: "#0d47a1",
    borderRadius: "50%",
    display: "inline-block",
  },
  hotelDot: {
    height: 10,
    width: 10,
    paddingRight: 5,
    backgroundColor: "#d50000",
    borderRadius: "50%",
    display: "inline-block",
  },
  hotel: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#d50000",
    borderRadius: 5,
    fontSize: ".8rem",
    padding: "1px 2px",
  },
  description: {
    fontSize: ".8rem",
    textAlign: "center",
    margin: 0,
    paddingTop: 10,
    backgroundColor: "#fafafa",
  },
  propertyName: {
    fontSize: ".8rem",
  },
  propertyAddress: {
    fontSize: ".8rem",
    margin: 0,
    padding: 0,
  },
  dotContainer: {
    margin: 0,
    //paddingLeft: 10
  },
  taxInfo: {
    fontSize: "1rem",
    textAlign: "center",
    paddingBottom: 10,
  },
  taxHeading: {
    fontSize: "1rem",
    paddingBottom: 20,
  },
  taxTitle: {
    fontSize: "1rem",
    padding: 0,
    marging: 0,
    display: "inline",
  },
  taxAddress: {
    textAlign: "left",
    fontSize: ".8rem",
  },
  expansionDetails: {
    backgroundColor: "#fafafa",
    marging: 0,
    padding: 0,
    height: "auto",
  },
  expansionDetails2: {
    backgroundColor: "#fafafa",
    margin: 0,
  },
  noPortfolioMsg: {
    color: "red",
  },
  linkMsg: {
    textDecoration: "underline",
  },
  portfolioLink: {
    marginLeft: "10px",
    color: "#448aff",
    textDecoration: "underline",
    fontSize: "1rem",
  },
  colMinWidth: {
    maxWidth: "160px!important",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      maxWidth: "150px!important",
      display: "flex",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "150px!important",
      display: "flex",
      flexDirection: "row",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "190px!important",
      display: "flex",
      flexDirection: "row",
    },
  },
  RevParMinWidth: {
    maxWidth: "140px!important",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      maxWidth: "130px!important",
      display: "flex",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "120px!important",
      display: "flex",
      flexDirection: "row",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "120px!important",
      display: "flex",
      flexDirection: "row",
    },
  },
  taxInfoWrapper: {
    padding: 12,
    paddingLeft: "10%",
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: 0,
      marginRight: "auto",
      marginLeft: "auto",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      padding: 0,
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  newOwner: {
    fontSize: "18px!important",
  },
  propertyChange: {
    width: "220px",
    fontSize: ".8rem",
    textAlign: "left",
    padding: "12px",
    margin: "0px",
    color: "#fff",
  },
  popoverCell: {
    minWidth: "100px!important",
    maxWidth: "210px!important",
  },
  overflow: {
    width: "100%",
    overflow: "auto",
  },
});

const longText = `
    This is the history of the names under which this property has filed its Texas State hotel occupancy tax. The dates reflect when the name was changed on state tax filings.
    `;
class HotelInfo extends React.Component {
  // using to cancel axios requests before component unmounts
  signal = axios.CancelToken.source();
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      rawposts: [],
      postscity: "",
      postsaddress: "",
      postscounty: "",
      postsfiler: "",
      postszip: "",
      newposts: [],
      getposts: [],
      ttmposts: [],
      ttmpostsrevpar: [],
      token: "",
      errormsg: "",
      portfoliotypes: [],
      portfolioSelected: {},
      poststimeline: [],
      noPortfoliosMsg: false,
      value: 0,
      yearlyfiler: 50,
      duration: "1",
      duration1: "1",
      durationttm: "1",
      durationttmrevpar: "1",
      type: "",
      title: "Add this Hotel to Portfolio",
      open: false,
      opensnack: false,
      showyear: false,
      vertical: "top",
      horizontal: "center",
      taxname: "",
      taxnum: "",
      taxaddress: "",
      taxcity: "",
      taxstate: "",
      taxzip: "",
      filtertable: true,
      otherTaxProperties: [],
      matchedAdresses: [],
      hotelName: this.props.match.params.hotelname
        .replace("(slash)", "/")
        .replace("(hash)", "#"),
      expanded: null,
      postsHotelName: "",
      postsLocationNum: "",
      dataLoadingStatustimeline: false,
      anchorEl: null,
      openedPopoverId: "",
      selectedRow: null,
      descendingPosts: "",
      //timeline state
      timelineDate: [],
      chartColorsIndex: 0,
      //tableLoading
      monthlyLoading: true,
      yearlyLoading: true,
      completeLoading: true,
      displayMessage: "SEARCHING...",
      latitude: "",
      longitude: "",
    };
  }

  // save to user history
  onSave() {
    let {
      taxnum,
      postsLocationNum,
      postsHotelName,
      postscity,
      postscounty,
    } = this.state;
    try {
      if (
        taxnum !== "" &&
        postsLocationNum !== "" &&
        postsHotelName !== "" &&
        postscity !== "" &&
        postscounty !== ""
      ) {
        axios({
          method: "post",
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
          },
          url: `${apiUrl}/rest/history`,
          data: {
            taxpayer_number: taxnum,
            location_number: postsLocationNum,
            location_name: postsHotelName,
            location_city: postscity,
            location_county: postscounty,
            type: 0,
          },
        }).catch(e => {
          ReactGA.exception({
            description: "An error occurred",
          });
          handleError(e, pathname);
          //window.location.replace('/login');
        });
      }
    } catch (err) {
      handleError(err, pathname);
    }
  }

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = name => event => {
    this.state.portfoliotypes.map(item => {
      if (item.id === event.target.value) {
        this.setState({ portfolioSelected: item });
      }
    });
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangetab = (event, value) => {
    this.setState({ value });
  };

  handleClick() {
    if (this.state.type === "") {
      this.setState({ errormsg: 1 });
      return;
    } else {
      this.setState({ errormsg: 0 });
    }

    // post to portfolio
    axios({
      method: "post",
      url: `${apiUrl}/rest/portfolio/hotel/`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
      data: {
        taxpayer_number: this.props.match.params.taxpayernumber,
        id: this.state.type,
        location_name: this.props.match.params.hotelname
          .replace("(slash)", "/")
          .replace("(hash)", "#"),
        location_number: this.props.match.params.locationnumber,
      },
    })
      .then(res => {
        try {
          if (res.data) {
            this.setState({ open: false });
            this.setState({ opensnack: true });
          }
        } catch (err) {
          handleError(err, pathname);
        }
      })
      .catch(e => {
        ReactGA.exception({
          description: "An error occurred",
        });
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
    if (this.state.portfolio === "") {
      this.setState({ errormsg: true });
      return;
    }
    // call to GET portfolio
    axios({
      method: "get",
      url: `${apiUrl}/rest/portfolio/`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
    })
      .then(res => {
        try {
          const rawposts1 = res.data;
          const updatedposts = [];
          if (res.data.length !== 0) {
            for (var i = 0; i < rawposts1.length; i++) {
              if (rawposts1[i].type === 0) {
                updatedposts[i] = rawposts1[i];
              }
            }
            this.setState({ portfoliotypes: updatedposts });
          } else {
            this.setState({ noPortfoliosMsg: true });
          }
        } catch (err) {
          handleError(err, pathname);
        }
      })
      .catch(e => {
        ReactGA.exception({
          description: "An error occurred",
        });
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.setState({ errormsg: 0 });
  };

  handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ opensnack: false });
  };

  // ===== Monthly =======
  handleChange1 = name => event => {
    this.setState({
      [name]: event.target.value,
    });

    try {
      let { descendingPosts } = this.state;
      const [metadata, data] = descendingPosts;
      const columns = [
        { type: "date", label: "Month" },
        { type: "number", label: "Total Revenue" },
      ];

      let rows = [];
      var d = new Date();
      var n = d.getFullYear();
      for (let row of descendingPosts) {
        let { date, room_receipts } = row;
        //  check if the row year is greather or equal to the outcome of years selected && less then or equal to that year.
        if (
          row.year >=
            descendingPosts[descendingPosts.length - 1].year -
              event.target.value &&
          row.year <= descendingPosts[descendingPosts.length - 1].year
        ) {
          date = new Date(row.year + "-" + row.month);
          date = new Date(row.year, row.month - 1, 1);
          rows.push([date, room_receipts]);
        }
      }
      this.setState({
        chartData: [columns, ...rows],
        dataLoadingStatus: "ready",
      });
    } catch (err) {
      handleError(err, pathname);
    }
  };

  // ==== REVPAR ========
  handleChangetaxable = name => event => {
    this.setState({
      [name]: event.target.value,
    });

    try {
      let { descendingPosts } = this.state;
      const [metadata, data] = descendingPosts;
      const columns1 = [
        { type: "date", label: "Month" },
        { type: "number", label: "RevPAR" },
      ];

      let rows1 = [];
      var d = new Date();
      var n = d.getFullYear();
      for (let row1 of descendingPosts) {
        let { date, revpar } = row1;
        if (
          row1.year >=
            descendingPosts[descendingPosts.length - 1].year -
              event.target.value &&
          row1.year <= descendingPosts[descendingPosts.length - 1].year
        ) {
          date = new Date(row1.year + "-" + row1.month);
          date = new Date(row1.year, row1.month - 1, 1);
          rows1.push([date, revpar]);
        }
      }
      this.setState({
        chartData1: [columns1, ...rows1],
        dataLoadingStatus1: "ready",
      });
    } catch (err) {
      handleError(err, pathname);
    }
  };

  // ===== TTM =======
  handleChangettm = name => event => {
    this.setState({
      [name]: event.target.value,
    });

    try {
      let { descendingPosts } = this.state;
      const [metadata, data] = descendingPosts;

      var p = 0;
      var q = 0;
      let ttmposts = [];
      const columnsttm = [
        { type: "date", label: "Month" },
        { type: "number", label: "TTM Revenue" },
      ];
      var currentd = new Date();
      var current = currentd.getFullYear();
      for (p = 0; p < descendingPosts.length; p++) {
        var ttm = 0;
        var count = 0;
        if (
          descendingPosts[p].year >=
            descendingPosts[descendingPosts.length - 1].year -
              event.target.value &&
          descendingPosts[p].year <=
            descendingPosts[descendingPosts.length - 1].year
        ) {
          let date = new Date(
            descendingPosts[p].year,
            descendingPosts[p].month - 1,
            1
          );
          for (q = p; q >= 0; q--) {
            ttm = ttm + descendingPosts[q].room_receipts;
            count++;
            if (count === 12) {
              count = 0;
              break;
            }
          }
          ttmposts.push([date, ttm]);
        }
      }
      this.setState({
        chartDatattm: [columnsttm, ...ttmposts],
        dataLoadingStatusttm: "ready",
      });
    } catch (err) {
      handleError(err, pathname);
    }
  };

  // === TTM REVPAR ====
  handleChangettmrevpar = name => event => {
    this.setState({
      [name]: event.target.value,
    });

    try {
      let { descendingPosts } = this.state;
      const [metadata, data] = descendingPosts;

      var p = 0;
      var q = 0;
      let ttmpostsrevpar = [];

      const columnsttmrevpar = [
        { type: "date", label: "Month" },
        { type: "number", label: "TTM RevPAR" },
      ];
      var currentd = new Date();
      var current = currentd.getFullYear();
      for (p = 0; p < descendingPosts.length; p++) {
        var ttmrevpar = 0;
        var count = 0;
        if (
          descendingPosts[p].year >=
            descendingPosts[descendingPosts.length - 1].year -
              event.target.value &&
          descendingPosts[p].year <=
            descendingPosts[descendingPosts.length - 1].year
        ) {
          let date = new Date(
            descendingPosts[p].year,
            descendingPosts[p].month - 1,
            1
          );
          for (q = p; q >= 0; q--) {
            var days = new Date(
              descendingPosts[q].year,
              descendingPosts[q].month - 1,
              0
            ).getDate();
            ttmrevpar =
              ttmrevpar +
              descendingPosts[q].room_receipts /
                (descendingPosts[q].room_capacity * days);
            count++;
            if (count === 12) {
              count = 0;
              break;
            }
          }
          ttmpostsrevpar.push([date, Math.ceil(ttmrevpar / 12)]);
        }
      }
      this.setState({
        chartDatattmrevpar: [columnsttmrevpar, ...ttmpostsrevpar],
        dataLoadingStatusttmrevpar: "ready",
      });
    } catch (err) {
      handleError(err, pathname);
    }
  };

  componentDidMount() {
    // variable to cancel axios request
    this.axiosCancelSource = axios.CancelToken.source();

    window.scrollTo(0, 0);

    const columnstimeline = [
      { type: "string", id: "Role" },
      { type: "string", id: "Name" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ];

    const rowstimeline = [];

    API.getOneHotelLatLong(
      this.props.match.params.taxpayernumber,
      this.props.match.params.locationnumber
    )
      .then(res => {
        console.log(`getOneHotelLatLong function called`);
        console.log(res.data);
        this.setState({
          latitude: res.data[0].latitude,
          longitude: res.data[0].longitude,
        });
      })
      .catch(err => console.log(err));

    API.postHotelHistory(
      this.props.match.params.taxpayernumber,
      this.props.match.params.locationnumber,
      this.axiosCancelSource.token
    )
      .then(res => {
        let unsortedPoststimeline = res.data;
        let { chartColorsIndex, timelineDate } = this.state;
        try {
        } catch (err) {
          handleError(err, pathname);
        }
        // Was getting google chart error for the property not sorted by date
        const poststimeline = unsortedPoststimeline.sort(
          (a, b) =>
            new moment(a.responsibility_begin_date).format("YYYYMMDD") -
            new moment(b.responsibility_begin_date).format("YYYYMMDD")
        );
        let postsHotelName;
        let postsaddress;
        this.setState({ poststimeline });

        // if there are more then one location name
        if (poststimeline.length > 1) {
          let latest = poststimeline.length - 1;

          postsHotelName = poststimeline[latest].location_name;
          postsaddress = poststimeline[latest].location_address;
          poststimeline.forEach(element => {
            element.color = chartColors[chartColorsIndex++];
            //console.log(this.state.chartColorsIndex);
            timelineDate[
              moment(element.responsibility_begin_date, "YYYYMMDD")
                .format("YYYY-MMMM")
                .toLowerCase()
            ] = element;
          });
          //console.log('timelineDate', timelineDate)

          let count = 1;
          for (var i = 0; i < poststimeline.length; i++) {
            //if  last....
            if (
              i + 1 == poststimeline.length &&
              poststimeline[i].taxpayer_name !== poststimeline[i - 1]
            ) {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name + " ",
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                moment(
                  poststimeline[i].responsibility_end_date || moment(),
                  "YYYYMMDD"
                ),
              ]);
              // rowstimeline.push(["Hotel Timeline", poststimeline[i].location_name + " Property under " + poststimeline[i].taxpayer_name,
              //   moment(poststimeline[i].responsibility_begin_date, 'YYYYMMDD'),
              //   moment()]);
            } else if (
              i !== 0 &&
              poststimeline[i].taxpayer_name !==
                poststimeline[i - 1].taxpayer_name
            ) {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name + addSpace(count),
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                moment(
                  poststimeline[i + 1].responsibility_begin_date,
                  "YYYYMMDD"
                ),
              ]);
              // rowstimeline.push(["Hotel Timeline" +" Property under " + poststimeline[i].taxpayer_name, poststimeline[i].location_name,
              //   moment(poststimeline[i].responsibility_begin_date, 'YYYYMMDD'),
              //   moment(poststimeline[i + 1].responsibility_begin_date, 'YYYYMMDD')])
            } else {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name,
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                moment(
                  poststimeline[i + 1].responsibility_begin_date,
                  "YYYYMMDD"
                ),
              ]);
            }
          }
          //console.log('rowstimeline', rowstimeline)
          this.setState({
            postsHotelName,
            postsaddress,
            dataLoadingStatustimeline: true,
            chartDatatimeline: [columnstimeline, ...rowstimeline],
          });
        } else {
          // put the only one property
          postsHotelName = poststimeline[0].location_name;
          postsaddress = poststimeline[0].location_address;

          poststimeline.forEach(element => {
            element.color = chartColors[chartColorsIndex++];
            timelineDate[
              moment(element.responsibility_begin_date, "YYYYMMDD")
                .format("YYYY-MMMM")
                .toLowerCase()
            ] = element;
          });
          //console.log('timelineDate', timelineDate)

          for (var i = 0; i < poststimeline.length; i++) {
            //if  last....
            if (i + 1 == poststimeline.length) {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name,
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                moment(),
              ]);
            } else {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name,
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                //moment(poststimeline[i + 1].responsibility_begin_date, 'YYYYMMDD').add(-1, 'month')])
                moment(
                  poststimeline[i + 1].responsibility_end_date,
                  "YYYYMMDD"
                ).add(-1, "month"),
              ]);
            }
          }
          //console.log('rowstimeline', rowstimeline)
          this.setState({
            postsHotelName,
            postsaddress,
            dataLoadingStatustimeline: true,
            chartDatatimeline: [columnstimeline, ...rowstimeline],
          });
        }

        API.getHotelFinanceRecordMonthly(
          this.props.match.params.hotelname,
          this.props.match.params.taxpayernumber,
          this.props.match.params.locationnumber,
          this.axiosCancelSource.token
        )
          .then(res => {
            console.log("this is the getHotelFinanceRecordMonthly res query");
            console.log(res);
            // raw --  from oldest to latest (ascending)
            const unsortedPosts = res.data;
            // from latest to oldest (descending)
            let posts = unsortedPosts.sort(function(a, b) {
              return new Date(a.date) - new Date(b.date);
            });
            this.setState({
              posts: posts,
              descendingPosts: unsortedPosts,
            });
            //console.log("this is the hotel posts", posts);
            // ====== NEED ANOTEHR WAY OF CHECKING FOR FILER TYPE =======
            //this.setState({ yearlyfiler: this.state.posts[posts.length - 1].filer_type });
            // console.log(this.state.posts[posts.length-1].filer_type);
            const postszip = posts[posts.length - 1].location_zip;
            const postscounty = posts[posts.length - 1].verified_county;
            const taxname = posts[posts.length - 1].taxpayer_name;
            const taxaddress = posts[posts.length - 1].taxpayer_address;
            const taxnum = posts[posts.length - 1].taxpayer_number;
            //const latestTaxnum = posts[posts.length - 1].taxpayer_number;
            const taxcity = posts[posts.length - 1].taxpayer_city;
            const taxstate = posts[posts.length - 1].taxpayer_state;
            const taxzip = posts[posts.length - 1].taxpayer_zip;
            const postsLocationNum = posts[posts.length - 1].location_number;
            const postscity = posts[posts.length - 1].location_city;
            const latestFiler_type = posts[posts.length - 1].filer_type;
            //console.log("latest filer", latestFiler_type);
            this.setState({
              postszip,
              postscounty,
              taxname,
              taxaddress,
              taxnum,
              taxcity,
              taxstate,
              taxzip,
              postsLocationNum,
              postscity,
              latestFiler_type,
            });

            if (latestFiler_type === 50) {
              this.setState({ postsfiler: "Monthly" });
            } else if (latestFiler_type === 60) {
              this.setState({ postsfiler: "Quarterly" });
            }

            API.getHotelFinanceRecordYearly(
              this.props.match.params.hotelname,
              this.props.match.params.taxpayernumber,
              this.props.match.params.locationnumber,
              this.state.latestFiler_type,
              this.axiosCancelSource.token
            )
              .then(res => {
                console.log("getHotelFinanceRecordYearly");
                console.log(res);
                var yearlyposts = res.data;
                //console.log("yearlyvalues", yearlyposts);
                for (var l = 0; l < yearlyposts.length; l++) {
                  // checking for the first year and setting RevChange and RevParChange to 0, since there is no comparision yet
                  if (l === 0) {
                    yearlyposts[l].totalRevChange = "$0.00 (0%)";
                    yearlyposts[l].RevPARChange = "$0.00 (0%)";
                  } else {
                    yearlyposts[l].totalRevChange =
                      "$ " +
                      (
                        yearlyposts[l].total_revenue -
                        yearlyposts[l - 1].total_revenue
                      ).toFixed(2) +
                      " (" +
                      (
                        (yearlyposts[l].total_revenue /
                          yearlyposts[l - 1].total_revenue) *
                          100 -
                        100
                      ).toFixed(2) +
                      "%)";
                    yearlyposts[l].RevPARChange =
                      "$ " +
                      (
                        yearlyposts[l].RevPar - yearlyposts[l - 1].RevPar
                      ).toFixed(2) +
                      " (" +
                      (
                        (yearlyposts[l].RevPar / yearlyposts[l - 1].RevPar) *
                          100 -
                        100
                      ).toFixed(2) +
                      "%)";
                  }
                }

                if (res.data !== undefined) {
                  axios
                    .get(
                      `${apiUrl}/rest/basic/testlastyearlyvalues/${
                        yearlyposts[yearlyposts.length - 1].year
                      }/${this.props.match.params.hotelname}/${
                        this.props.match.params.taxpayernumber
                      }/${
                        this.props.match.params.locationnumber
                      }/${latestFiler_type}`,
                      {
                        headers: {
                          authorization:
                            "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
                        },
                        cancelToken: this.axiosCancelSource.token,
                      }
                    )
                    .then(res1 => {
                      //console.log("lastyear value", res1);
                      // gets last year's totals and substracts to yearlyposts same year & caclulates percentage
                      if (res1.data[0]) {
                        yearlyposts[yearlyposts.length - 1].totalRevChange =
                          "$ " +
                          (
                            yearlyposts[yearlyposts.length - 1].total_revenue -
                            res1.data[0].total_revenue
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                          " (" +
                          (
                            (yearlyposts[yearlyposts.length - 1].total_revenue /
                              res1.data[0].total_revenue) *
                              100 -
                            100
                          ).toFixed(2) +
                          "%)";
                        yearlyposts[yearlyposts.length - 1].RevPARChange =
                          "$ " +
                          (
                            yearlyposts[yearlyposts.length - 1].RevPar -
                            res1.data[0].RevPar
                          ).toFixed(2) +
                          " (" +
                          (
                            (yearlyposts[yearlyposts.length - 1].RevPar /
                              res1.data[0].RevPar) *
                              100 -
                            100
                          ).toFixed(2) +
                          "%)";

                        this.setState({
                          yearlyposts: yearlyposts,
                          yearlyLoading: false,
                        });
                      }
                    })
                    .catch(e => {
                      handleError(e, pathname);
                    });
                }
              })
              .catch(e => {
                handleError(e, pathname);
              });

            // Then set the document Title
            document.title =
              this.state.postsHotelName +
              ", " +
              this.state.postscity +
              ",TX - Search Texas Tax";
            ReactGA.initialize("UA-80211377-3");
            ReactGA.pageview(window.location.pathname);

            // ==== Start of google charts =====
            // ====== TTM Revpar ========
            var p = 0;
            var q = 0;
            let ttmposts = [];
            let ttmpostsrevpar = [];
            const columnsttm = [
              { type: "date", label: "Month" },
              { type: "number", label: "TTM Revenue" },
            ];
            const columnsttmrevpar = [
              { type: "date", label: "Month" },
              { type: "number", label: "TTM RevPAR" },
            ];
            var currentd = new Date();
            var current = currentd.getFullYear();

            for (p = 0; p < posts.length; p++) {
              var ttm = 0;
              var ttmrevpar = 0;
              var count = 0;

              if (
                posts[p].year >= posts[posts.length - 1].year - 1 &&
                posts[p].year <= posts[posts.length - 1].year
              ) {
                let date = new Date(posts[p].year, posts[p].month - 1, 1);

                for (q = p; q >= 0; q--) {
                  ttm = ttm + posts[q].room_receipts;
                  var days = new Date(
                    posts[q].year,
                    posts[q].month - 1,
                    0
                  ).getDate();

                  ttmrevpar =
                    ttmrevpar +
                    posts[q].room_receipts / (posts[q].room_capacity * days);
                  count++;
                  if (count === 12) {
                    count = 0;
                    break;
                  }
                }
                ttmposts.push([date, ttm]);
                ttmpostsrevpar.push([date, Math.ceil(ttmrevpar / 12)]);
              }
            }
            let ttmpostsSorted = ttmposts.sort(function(a, b) {
              return a[0] - b[0];
            });
            let ttmpostsrevparSorted = ttmpostsrevpar.sort(function(a, b) {
              return a[0] - b[0];
            });

            // console.log("ttmpostsSorted", ttmpostsSorted )
            // console.log("ttmpostsrevparSorted ", ttmpostsrevparSorted  )

            this.setState({
              ttmpostsrevpar: ttmpostsrevpar,
              chartDatattm: [columnsttm, ...ttmpostsSorted],
              dataLoadingStatusttm: "ready",
              chartDatattmrevpar: [columnsttmrevpar, ...ttmpostsrevparSorted],
              dataLoadingStatusttmrevpar: "ready",
            });

            //  ===== Monthy =====
            const [metadata, data] = res.data;
            const columns = [
              { type: "date", label: "Month" },
              { type: "number", label: "Total Revenue" },
            ];
            const columns1 = [
              { type: "date", label: "Month" },
              { type: "number", label: "Taxable Revenue" },
            ];
            let rows = [];
            let rows1 = [];
            var d = new Date();
            var n = d.getFullYear();
            for (let row of unsortedPosts) {
              const { date, room_receipts } = row;
              if (
                row.year >= unsortedPosts[unsortedPosts.length - 1].year - 1 &&
                row.year <= unsortedPosts[unsortedPosts.length - 1].year
              ) {
                date = new Date(row.year + "-" + row.month);
                date = new Date(row.year, row.month - 1, 1);
                rows.push([date, room_receipts]);
              }
            }
            this.setState({
              chartData: [columns, ...rows],
              dataLoadingStatus: "ready",
            });

            // ===== RevPar =====
            for (let row1 of unsortedPosts) {
              const { date, revpar } = row1;
              if (
                row1.year >= unsortedPosts[unsortedPosts.length - 1].year - 1 &&
                row1.year <= unsortedPosts[unsortedPosts.length - 1].year
              ) {
                //date = new Date(row1.year+"-"+row1.month);
                date = new Date(row1.year, row1.month - 1, 1);
                rows1.push([date, revpar]);
              }
            }
            this.setState({
              chartData1: [columns1, ...rows1],
              dataLoadingStatus1: "ready",
            });
            const rawposts = res.data;
            this.setState({
              rawposts: rawposts,
              completeLoading: false,
            });

            var i = 0;
            var j = 0;
            var a = 0;
            var b = 0;
            var newposts = [];

            for (i = 0; i < posts.length; i++) {
              if (a === 0) {
                newposts.push(new Object());
                newposts[newposts.length - 1].year = posts[i].year;

                newposts[newposts.length - 1].taxPayerNum =
                  posts[i].taxpayer_number;
                newposts[newposts.length - 1].locationName =
                  posts[i].location_name;

                if (posts[i].month === 1) {
                  newposts[newposts.length - 1].january =
                    posts[i].room_receipts;
                }
                if (posts[i].month === 2) {
                  newposts[newposts.length - 1].february =
                    posts[i].room_receipts;
                }
                if (posts[i].month === 3) {
                  newposts[newposts.length - 1].march = posts[i].room_receipts;
                }
                if (posts[i].month === 4) {
                  newposts[newposts.length - 1].april = posts[i].room_receipts;
                }
                if (posts[i].month === 5) {
                  newposts[newposts.length - 1].may = posts[i].room_receipts;
                }
                if (posts[i].month === 6) {
                  newposts[newposts.length - 1].june = posts[i].room_receipts;
                }
                if (posts[i].month === 7) {
                  newposts[newposts.length - 1].july = posts[i].room_receipts;
                }
                if (posts[i].month === 8) {
                  newposts[newposts.length - 1].august = posts[i].room_receipts;
                }
                if (posts[i].month === 9) {
                  newposts[newposts.length - 1].september =
                    posts[i].room_receipts;
                }
                if (posts[i].month === 10) {
                  newposts[newposts.length - 1].october =
                    posts[i].room_receipts;
                }
                if (posts[i].month === 11) {
                  newposts[newposts.length - 1].november =
                    posts[i].room_receipts;
                }
                a = 1;
                if (posts[i].month === 12) {
                  newposts[newposts.length - 1].december =
                    posts[i].room_receipts;
                  a = 0;
                }
                continue;
              }

              for (j = 0; j < newposts.length; j++) {
                if (newposts[j].year === posts[i].year) {
                  if (posts[i].month === 1) {
                    newposts[j].january = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 2) {
                    newposts[j].february = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 3) {
                    newposts[j].march = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 4) {
                    newposts[j].april = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 5) {
                    newposts[j].may = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 6) {
                    newposts[j].june = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 7) {
                    newposts[j].july = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 8) {
                    newposts[j].august = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 9) {
                    newposts[j].september = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 10) {
                    newposts[j].october = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 11) {
                    newposts[j].november = posts[i].room_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year != posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 12) {
                    newposts[j].december = posts[i].room_receipts;
                    a = 0;
                  }
                  break;
                }
              }
            }
            //console.log("newposts", newposts);
            let newpostsReversed = newposts.reverse();
            this.setState({
              newposts: newpostsReversed,
              monthlyLoading: false,
            });

            var getposts = [];
            for (let i = 0; i < newposts.length; i++) {
              getposts.push(new Object());
              getposts[i].Year = newposts[i].year;
              getposts[i].January = newposts[i].january || 0;
              getposts[i].February = newposts[i].february || 0;
              getposts[i].March = newposts[i].march || 0;
              getposts[i].Q1 =
                (newposts[i].january || 0) +
                (newposts[i].february || 0) +
                (newposts[i].march || 0);
              getposts[i].April = newposts[i].april || 0;
              getposts[i].May = newposts[i].may || 0;
              getposts[i].June = newposts[i].june || 0;
              getposts[i].Q2 =
                (newposts[i].april || 0) +
                (newposts[i].may || 0) +
                (newposts[i].june || 0);
              getposts[i].July = newposts[i].july || 0;
              getposts[i].August = newposts[i].august || 0;
              getposts[i].September = newposts[i].september || 0;
              getposts[i].Q3 =
                (newposts[i].july || 0) +
                (newposts[i].august || 0) +
                (newposts[i].september || 0);
              getposts[i].October = newposts[i].october || 0;
              getposts[i].November = newposts[i].november || 0;
              getposts[i].December = newposts[i].december || 0;
              getposts[i].Q4 =
                (newposts[i].october || 0) +
                (newposts[i].november || 0) +
                (newposts[i].december || 0);
            }
            this.setState({ getposts: getposts, monthlyLoading: false });

            // ====== Other Calls ========
            // ===== Checking for Other Properties under same Tax Payer number =====
            axios
              .get(
                `${apiUrl}/rest/search/taxpayerproperties/${this.props.match.params.taxpayernumber}`,
                {
                  headers: {
                    authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
                  },
                  cancelToken: this.axiosCancelSource.token,
                }
              )
              .then(res => {
                if (res.data.length > 1) {
                  let otherProperties = res.data;
                  let taxProperties = [];
                  // check if other property has the same name and type to remove from propertieslist
                  for (let i = 0; i < otherProperties.length; i++) {
                    if (
                      otherProperties[i].location_name ===
                        this.state.hotelName &&
                      otherProperties[i].location_address ===
                        this.state.postsaddress &&
                      otherProperties[i].type === "hotel"
                    ) {
                      continue;
                    }
                    taxProperties.push(otherProperties[i]);
                  }
                  this.setState({ otherTaxProperties: taxProperties });
                }
              })
              .catch(e => {
                ReactGA.exception({
                  description: "An error occurred",
                });
                handleError(e, pathname);
                //window.location.replace('/login');
              });
            axios({
              method: "post",
              url: `${apiUrl}/rest/search/addressmatch/`,
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
              },
              data: {
                address: this.state.postsaddress,
                zip: this.state.postszip,
              },
              cancelToken: this.axiosCancelSource.token,
            })
              .then(res => {
                if (res.data.length > 1) {
                  let otherProperties = res.data;
                  let matchLocation = [];
                  for (let i = 0; i < otherProperties.length; i++) {
                    if (
                      otherProperties[i].location_name ===
                        this.state.hotelName &&
                      otherProperties[i].type === "hotel"
                    ) {
                      continue;
                    }
                    if (otherProperties[i].type === "hotel") {
                      continue;
                    }
                    matchLocation.push(otherProperties[i]);
                  }
                  console.log(matchLocation);
                  this.setState({ matchedAdresses: matchLocation });
                }
              })
              .catch(e => {
                ReactGA.exception({
                  description: "An error occurred",
                });
                handleError(e, pathname);
                //window.location.replace('/login');
              });
          })
          .catch(e => {
            ReactGA.exception({
              description: "An error occurred",
            });
            handleError(e, pathname);
            //window.location.replace('/login');
          });
      })
      .catch(e => {
        ReactGA.exception({
          description: "An error occurred",
        });
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }

  componentWillUnmount() {
    this.onSave();
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  render() {
    //const { vertical, horizontal, opensnack } = this.state;
    let errormsg;
    if (this.state.errormsg === 1) {
      errormsg = (
        <p className="errormsg">
          Please Select a Portfolio to Add this Hotel to it.
        </p>
      );
    }

    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    let chartdisplaytime;
    if (this.state.dataLoadingStatustimeline) {
      chartdisplaytime = (
        <Chart
          chartType="Timeline"
          graph_id={shortid.generate()}
          loader={<CircularProgress className="loading" size={50} />}
          data={this.state.chartDatatimeline}
          width="100%"
          height="100px"
          options={{
            timeline: { groupByRowLabel: true },
            //timeline: { groupByRowLabel: false },
            avoidOverlappingGridLines: false,
            colors: chartColors,
          }}
        />
      );
    } else {
      chartdisplaytime = <CircularProgress className="loading" size={50} />;
    }

    let chartdisplay;
    if (this.state.dataLoadingStatus === "ready") {
      chartdisplay = (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="outlined-select-currency"
              select
              className={classes.textField}
              value={this.state.duration}
              onChange={this.handleChange1("duration")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {durations.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <Chart
            chartType="Line"
            graph_id={shortid.generate()}
            loader={<div>Loading Chart</div>}
            width={"100%"}
            height={"400px"}
            data={this.state.chartData}
            options={{
              chartArea: { height: "100%", width: "90%" },
              hAxis: {
                format: "yyyy",
              },
              vAxis: {
                format: "short",
              },
              title: "Debt incurred over time.",
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: "Total_Revenue" },
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Revenue: { label: "Monthly Revenue" },
                  all: {
                    format: {
                      pattern: "currency",
                    },
                  },
                },
                x: {
                  all: {
                    format: {
                      pattern: "MMM. yyyy",
                    },
                  },
                },
              },
              legend: { position: "none" },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      );
    } else {
      chartdisplay = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }

    let chartdisplay1;
    if (this.state.dataLoadingStatus1 === "ready") {
      chartdisplay1 = (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="outlined-select-currency"
              select
              className={classes.textField}
              value={this.state.duration1}
              onChange={this.handleChangetaxable("duration1")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {durations1.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <Chart
            chartType="Line"
            graph_id={shortid.generate()}
            loader={<div>Loading Chart</div>}
            width={"100%"}
            height={"400px"}
            data={this.state.chartData1}
            options={{
              chartArea: { height: "100%", width: "90%" },
              hAxis: {
                format: "yyyy",
              },
              vAxis: {
                format: "short",
              },
              title: "Debt incurred over time.",
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: "Total_Revenue" },
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Revenue: { label: "Monthly Revenue" },
                  all: {
                    format: {
                      pattern: "currency",
                    },
                  },
                },
                x: {
                  all: {
                    format: {
                      pattern: "MMM. yyyy",
                    },
                  },
                },
              },
              legend: { position: "none" },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      );
    } else {
      chartdisplay1 = <div> Fetching Data from API</div>;
    }

    let chartdisplayttm;
    if (this.state.dataLoadingStatusttm === "ready") {
      chartdisplayttm = (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="outlined-select-currency"
              select
              className={classes.textField}
              value={this.state.durationttm}
              onChange={this.handleChangettm("durationttm")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {durationsttm.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <Chart
            chartType="Line"
            graph_id={shortid.generate()}
            loader={<div>Loading Chart</div>}
            width={"100%"}
            height={"400px"}
            data={this.state.chartDatattm}
            options={{
              chartArea: { height: "100%", width: "90%" },
              hAxis: {
                format: "yyyy",
              },
              vAxis: {
                format: "short",
              },
              title: "Debt incurred over time.",
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: "TTM_Revenue" },
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Revenue: { label: "Monthly Revenue" },
                  all: {
                    format: {
                      pattern: "currency",
                    },
                  },
                },
                x: {
                  all: {
                    format: {
                      pattern: "MMM. yyyy",
                    },
                  },
                },
              },
              legend: { position: "none" },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      );
    } else {
      chartdisplayttm = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }

    let chartdisplayttmrevpar;
    if (this.state.dataLoadingStatusttmrevpar === "ready") {
      chartdisplayttmrevpar = (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="outlined-select-currency"
              select
              className={classes.textField}
              value={this.state.durationttmrevpar}
              onChange={this.handleChangettmrevpar("durationttmrevpar")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {durationsttmrevpar.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <Chart
            chartType="Line"
            graph_id={shortid.generate()}
            loader={<div>Loading Chart</div>}
            width={"100%"}
            height={"400px"}
            data={this.state.chartDatattmrevpar}
            options={{
              chartArea: { height: "100%", width: "90%" },
              hAxis: {
                format: "yyyy",
              },
              vAxis: {
                format: "short",
              },
              title: "Debt incurred over time.",
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: "TTM_RevPAR" },
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Revenue: { label: "Monthly Revenue" },
                  all: {
                    format: {
                      pattern: "currency",
                    },
                  },
                },
                x: {
                  all: {
                    format: {
                      pattern: "MMM. yyyy",
                    },
                  },
                },
              },
              legend: { position: "none" },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      );
    } else {
      chartdisplayttmrevpar = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }
    const {
      value,
      otherTaxProperties,
      matchedAdresses,
      timelineDate,
    } = this.state;

    return (
      <div>
        <Grid container spacing={3} justify="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={this.state.latitude && this.state.longitude ? 8 : 12}
          >
            <Paper className="data_title">
              <div className="row">
                <div className="col">
                  <h2 style={{ padding: 0, margin: 0 }}>
                    {this.state.postsHotelName}
                  </h2>
                </div>
                <div className="col">
                  <Typography className={classes.subtext} color="textSecondary">
                    {this.state.postsaddress},{" "}
                    <Link
                      className="clickable"
                      to={`/stt/city/${this.state.postscity}`}
                    >
                      {this.state.postscity}
                    </Link>
                    , TX {this.state.postszip} &#x25CF;{" "}
                    <Link
                      className="clickable"
                      to={`/stt/county/${this.state.postsCountyName}`}
                    >
                      {" "}
                      {this.state.postsCountyName}
                    </Link>{" "}
                    County
                    <Tooltip title={longText} placement="right-end">
                      <Icon className={classes.Green}>contact_support</Icon>
                    </Tooltip>
                  </Typography>
                </div>
                {/* <div className="col question-mark">
                  <Tooltip title={longText} placement="right-end">
                    <Icon className={classes.Green}>contact_support</Icon>
                  </Tooltip>
                </div> */}
                <div className="clear"></div>
                <div className="col">{chartdisplaytime}</div>
              </div>
            </Paper>
          </Grid>
          {this.state.latitude && this.state.longitude && (
            <Grid item xs={12} sm={6} md={4}>
              <Paper className={classes.map}>
                <GoogleMapReact
                  lat={this.state.latitude}
                  long={this.state.longitude}
                  address={this.state.location_address}
                />
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card}>
              <CardHeader title="Mixed Beverage Records" />
              {matchedAdresses.length > 0 ? (
                matchedAdresses.slice(0, 10).map((item, index) => (
                  <React.Fragment key={index}>
                    <CardContent className={`${classes.listItem}`}>
                      <Link
                        className={classes.propertyLink}
                        to={`/stt/mbthotelinfo/${item.location_name
                          .replace("/", "(slash)")
                          .replace("#", "(hash)")}/${item.taxpayer_number}/${
                          item.location_number
                        }`}
                      >
                        <Typography className={classes.typography}>
                          <strong>{item.location_name}</strong>
                        </Typography>
                        <Typography className={classes.typography3}>
                          {item.location_address},{item.location_state},
                          {item.location_zip}
                        </Typography>
                      </Link>
                    </CardContent>
                  </React.Fragment>
                ))
              ) : (
                <CardContent>
                  <Typography>There are no other properties.</Typography>
                </CardContent>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {/* <Paper style={{height: '25vh'}}> */}
            <Paper className={classes.card}>
              <CardHeader title="Owner Information" />
              <CardContent className={classes.listItem}>
                <Typography className={classes.typography}>
                  <strong>{this.state.taxname}</strong>
                </Typography>
                <Typography className={classes.typography3}>
                  {this.state.taxaddress}
                </Typography>
                <Typography className={classes.typography3}>
                  {this.state.taxcity}, {this.state.taxstate},{" "}
                  {this.state.taxzip}
                </Typography>
              </CardContent>
              <CardContent className={classes.listItem}>
                <Typography>
                  <strong>Taxpayer Number: </strong>{" "}
                  <span>{this.state.taxnum}</span>
                </Typography>
                <Typography>
                  <strong>Filer Type: </strong>
                  <span>{this.state.postsfiler}</span>
                </Typography>
              </CardContent>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {/* <Paper style={{height: '25vh', overflow: 'auto'}}> */}
            <Paper className={classes.card}>
              <CardHeader title="Taxpayer Other Properties" />
              <List>
                {otherTaxProperties.length > 0 ? (
                  otherTaxProperties.map((item, index) => (
                    <React.Fragment>
                      <Link
                        className={classes.propertyLink}
                        to={{
                          pathname: "/stt/mbtredirect",
                          state: `/stt/hotelinfo/${item.location_name
                            .replace("/", "(slash)")
                            .replace("#", "(hash)")}/${item.taxpayer_number}/${
                            item.location_number
                          }`,
                        }}
                      >
                        <ListItem>
                          {item.type === "hotel" ? (
                            <Chip
                              style={{ marginRight: 10 }}
                              color="secondary"
                              label="Hotel"
                              size="small"
                              mx={2}
                            />
                          ) : (
                            <Chip
                              style={{ marginRight: 10 }}
                              color="primary"
                              label="MBT"
                              size="small"
                              mx={2}
                            />
                          )}
                          <ListItemText
                            style={{ padding: 0, margin: 0 }}
                            className={classes.typography}
                            primary={<strong>{item.location_name}</strong>}
                            secondary={
                              <React.Fragment>
                                <Typography className={classes.typography3}>
                                  {item.location_address}, {item.location_city},{" "}
                                  {item.location_state}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          <Divider />
                        </ListItem>
                      </Link>
                    </React.Fragment>
                  ))
                ) : (
                  <CardContent>
                    <Typography>There are no other properties.</Typography>
                  </CardContent>
                )}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} className="chartmobilegrid">
            <Paper className="">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={this.handleChangetab}
                  scrollable
                  scrollButtons="auto"
                >
                  <Tab label="Monthly Revenue" />
                  <Tab label="RevPAR" />
                  <Tab label="TTM Revenue" />
                  <Tab label="TTM RevPAR" />
                </Tabs>
              </AppBar>
              {value === 0 && <TabContainer>{chartdisplay}</TabContainer>}
              {value === 1 && <TabContainer>{chartdisplay1}</TabContainer>}
              {value === 2 && <TabContainer>{chartdisplayttm}</TabContainer>}
              {value === 3 && (
                <TabContainer>{chartdisplayttmrevpar}</TabContainer>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} className="tablehead1">
            <CSVLink
              data={this.state.getposts}
              className="csvdownload"
              filename="Monthly&QuarterlyResults.csv"
            >
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>
            <MaterialTable
              isLoading={this.state.monthlyLoading}
              columns={[
                {
                  title: "Year",
                  field: "year",
                  type: "numeric",
                  defaultSort: "desc",
                },
                {
                  title: "January",
                  field: "january",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-january`] ===
                      "undefined"
                    ) {
                      return rowData.january ? (
                        <div>
                          $&nbsp;
                          {rowData.january
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.january ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.january
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="january"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "February",
                  field: "february",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-february`] ===
                      "undefined"
                    ) {
                      return rowData.february ? (
                        <div>
                          $&nbsp;
                          {rowData.february
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.february ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.february
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="february"
                            popId={React.createRef()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "March",
                  field: "march",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-march`] ===
                      "undefined"
                    ) {
                      return rowData.march ? (
                        <div>
                          $&nbsp;
                          {rowData.march
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.march ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.march
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="march"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "Quarter 1",
                  field: "march",
                  type: "html",
                  render: rowData => {
                    return (
                      <div style={{ fontWeight: "700" }}>
                        $&nbsp;
                        {(
                          (rowData.january || 0) +
                          (rowData.february || 0) +
                          (rowData.march || 0)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                  cellStyle: {
                    backgroundColor: "#f5f5f5",
                  },
                  headerStyle: {
                    fontWeight: "800",
                    maxWidth: "125px",
                    //backgroundColor: '#fafafa',
                  },
                },
                {
                  title: "April",
                  field: "april",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-april`] ===
                      "undefined"
                    ) {
                      return rowData.april ? (
                        <div>
                          $&nbsp;
                          {rowData.april
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$ 0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.april ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.april
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="april"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "May",
                  field: "may",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-may`] === "undefined"
                    ) {
                      return rowData.may ? (
                        <div>
                          $&nbsp;
                          {rowData.may
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$ 0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.may ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.may
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="may"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "June",
                  field: "june",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-june`] ===
                      "undefined"
                    ) {
                      return rowData.june ? (
                        <div>
                          $&nbsp;
                          {rowData.june
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$ 0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.june ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.june
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="june"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: `Quarter 2`,
                  field: "june",
                  type: "html",
                  render: rowData => {
                    return (
                      <div style={{ fontWeight: "700" }}>
                        $&nbsp;
                        {(
                          (rowData.april || 0) +
                          (rowData.may || 0) +
                          (rowData.june || 0)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                  cellStyle: {
                    backgroundColor: "#f5f5f5",
                  },
                  headerStyle: {
                    fontWeight: "800",
                    maxWidth: "125px",
                  },
                },
                {
                  title: "July",
                  field: "july",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-july`] ===
                      "undefined"
                    ) {
                      return rowData.july ? (
                        <div>
                          $&nbsp;
                          {rowData.july
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$ 0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.july ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.july
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="july"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "August",
                  field: "august",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-august`] ===
                      "undefined"
                    ) {
                      return rowData.august ? (
                        <div>
                          $&nbsp;
                          {rowData.august
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$ 0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.august ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.august
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="august"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "September",
                  field: "september",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-september`] ===
                      "undefined"
                    ) {
                      return rowData.september ? (
                        <div>
                          $&nbsp;
                          {rowData.september
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$ 0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.september ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.september
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="september"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "Quarter 3",
                  field: "september",
                  type: "html",
                  render: rowData => {
                    return (
                      <div style={{ fontWeight: "700" }}>
                        $&nbsp;
                        {(
                          (rowData.july || 0) +
                          (rowData.august || 0) +
                          (rowData.september || 0)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                  cellStyle: {
                    backgroundColor: "#f5f5f5",
                  },
                  headerStyle: {
                    fontWeight: "800",
                    maxWidth: "125px",
                  },
                },
                {
                  title: "October",
                  field: "october",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-october`] ===
                      "undefined"
                    ) {
                      return rowData.october ? (
                        <div>
                          $&nbsp;
                          {rowData.october
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$ 0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.october ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.october
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="october"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "November",
                  field: "november",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-november`] ===
                      "undefined"
                    ) {
                      return rowData.november ? (
                        <div>
                          $&nbsp;
                          {rowData.november
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$ 0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.november ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.november
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="november"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "December",
                  field: "december",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-december`] ===
                      "undefined"
                    ) {
                      return rowData.december ? (
                        <div>
                          $&nbsp;
                          {rowData.december
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$ 0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.december ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.december
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="december"
                            popId={shortid.generate()}
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "Quarter 4",
                  field: "december",
                  type: "html",
                  render: rowData => {
                    return (
                      <div style={{ fontWeight: "700" }}>
                        $&nbsp;
                        {(
                          (rowData.october || 0) +
                          (rowData.november || 0) +
                          (rowData.december || 0)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                  cellStyle: {
                    backgroundColor: "#f5f5f5",
                  },
                  headerStyle: {
                    fontWeight: "800",
                    maxWidth: "125px",
                  },
                },
              ]}
              data={this.state.newposts}
              title={
                this.state.postsHotelName
                  ? `Monthly & Quarterly Performance: ${this.state.postsHotelName} `
                  : null
              }
              onRowClick={(evt, selectedRow) => this.setState({ selectedRow })}
              options={{
                //pageSize: 10,
                columnsButton: true,
                rowStyle: rowData => ({
                  backgroundColor:
                    this.state.selectedRow &&
                    this.state.selectedRow.tableData.id === rowData.tableData.id
                      ? "#f5f5f5"
                      : "#FFF",
                }),
                loadingType: "linear",
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: this.state.displayMessage,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} className="tablehead1">
            {this.state.yearlyposts ? (
              <CSVLink
                data={this.state.yearlyposts}
                className="csvdownload"
                filename="YearlyRevenueResults.csv"
              >
                <Button className="csvbutton" title="Export As CSV">
                  <Icon className="csvicon">save_alt</Icon>
                </Button>
              </CSVLink>
            ) : null}
            <MaterialTable
              isLoading={this.state.yearlyLoading}
              columns={[
                {
                  title: "Year",
                  field: "year",
                  type: "numeric",
                  defaultSort: "desc",
                },
                {
                  title: "Total Revenue",
                  field: "total_revenue",
                  type: "html",
                  render: rowData => {
                    return (
                      <div>
                        $&nbsp;
                        {rowData.total_revenue
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "Change in Revenue",
                  field: "totalRevChange",
                  render: rowData => {
                    if (rowData.totalRevChange.includes("-")) {
                      return (
                        <Grid container className={classes.colMinWidth}>
                          <Grid item xs={11}>
                            {rowData.totalRevChange}
                          </Grid>
                          <Grid item xs={1}>
                            <Icon className={classes.Red}>arrow_downward</Icon>
                          </Grid>
                        </Grid>
                      );
                    } else if (rowData.totalRevChange !== "$0.00 (0%)") {
                      let checkedData;
                      if (rowData.totalRevChange.includes("(Infinity%)")) {
                        checkedData = rowData.totalRevChange.replace(
                          "(Infinity%)",
                          " "
                        );
                        return (
                          <Grid container className={classes.colMinWidth}>
                            <Grid item xs={11}>
                              {checkedData}
                              <span>
                                (<strong> &#8734; </strong>%)
                              </span>
                            </Grid>
                            <Grid item xs={1}>
                              <Icon className={classes.Green}>
                                arrow_upward
                              </Icon>
                            </Grid>
                          </Grid>
                        );
                      } else {
                        checkedData = rowData.totalRevChange;
                        return (
                          <Grid container className={classes.colMinWidth}>
                            <Grid item xs={11}>
                              {rowData.totalRevChange}
                            </Grid>
                            <Grid item xs={1}>
                              <Icon className={classes.Green}>
                                arrow_upward
                              </Icon>
                            </Grid>
                          </Grid>
                        );
                      }
                    } else {
                      return (
                        <Grid container className={classes.colMinWidth}>
                          <Grid item xs={11}>
                            {rowData.totalRevChange}
                          </Grid>
                          <Grid item xs={1}>
                            <Icon className={classes.Grey}>minimize</Icon>
                          </Grid>
                        </Grid>
                      );
                    }
                  },
                  type: "html",
                },
                {
                  title: "RevPar",
                  field: "RevPar",
                  type: "html",
                  render: rowData => {
                    return <div>$ {rowData.RevPar.toFixed(2)}</div>;
                  },
                },
                {
                  title: "Change in RevPar",
                  field: "RevPARChange",
                  render: rowData => {
                    if (rowData.RevPARChange.includes("-")) {
                      return (
                        <Grid container className={classes.RevParMinWidth}>
                          <Grid item xs={11}>
                            {rowData.RevPARChange}
                          </Grid>
                          <Grid item xs={1}>
                            <Icon className={classes.Red}>arrow_downward</Icon>
                          </Grid>
                        </Grid>
                      );
                    } else if (rowData.RevPARChange !== "$0.00 (0%)") {
                      let checkedData;
                      if (rowData.RevPARChange.includes("(Infinity%)")) {
                        checkedData = rowData.RevPARChange.replace(
                          "(Infinity%)",
                          " "
                        );
                        return (
                          <Grid container className={classes.RevParMinWidth}>
                            <Grid item xs={11}>
                              {checkedData}
                              <span>
                                (<strong> &#8734; </strong>%)
                              </span>
                            </Grid>
                            <Grid item xs={1}>
                              <Icon className={classes.Green}>
                                arrow_upward
                              </Icon>
                            </Grid>
                          </Grid>
                        );
                      } else {
                        checkedData = rowData.RevPARChange;
                        return (
                          <Grid container className={classes.RevParMinWidth}>
                            <Grid item xs={11}>
                              {rowData.RevPARChange}
                            </Grid>
                            <Grid item xs={1}>
                              <Icon className={classes.Green}>
                                arrow_upward
                              </Icon>
                            </Grid>
                          </Grid>
                        );
                      }
                    } else
                      return (
                        <Grid container className={classes.RevParMinWidth}>
                          <Grid item xs={11}>
                            {rowData.RevPARChange}
                          </Grid>
                          <Grid item xs={1}>
                            <Icon className={classes.Grey}>minimize</Icon>
                          </Grid>
                        </Grid>
                      );
                  },
                  type: "html",
                },
              ]}
              data={this.state.yearlyposts}
              title={`Yearly Revenue: ${this.state.postsHotelName}`}
              options={{
                columnsButton: true,
                loadingType: "linear",
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: this.state.displayMessage,
                },
              }}
              // components={{
              //   OverlayLoading: props => (
              //     <div style={{ backgroundColor: '#e8eaf5', marginTop: '50px!important', border: '1px solid red!important' }}>
              //       <h1>TEST</h1>
              //       <CircularProgress {...props} className="loading" size={50} />
              //     </div>
              //   )
              // }}
            />
          </Grid>
          <Grid item xs={12} className="tablehead1">
            <CSVLink
              data={this.state.rawposts}
              className="csvdownload"
              filename="CompleteDataSet.csv"
            >
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>
            <MaterialTable
              isLoading={this.state.completeLoading}
              columns={[
                {
                  title: "Year",
                  field: "year",
                  type: "numeric",
                  defaultSort: "desc",
                },
                { title: "Month", field: "month", type: "numeric" },
                {
                  title: "Total Number of Rooms",
                  field: "room_capacity",
                  type: "numeric",
                },
                {
                  title: "Location Address",
                  field: "location_address",
                  hidden: true,
                },
                {
                  title: "Location City",
                  field: "location_city",
                  hidden: true,
                },
                { title: "Location Zip", field: "location_zip", hidden: true },
                {
                  title: "Total Revenue",
                  field: "room_receipts",
                  type: "html",
                  render: rowData => {
                    return (
                      <div>
                        ${" "}
                        {rowData.room_receipts
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "Taxable Revenue",
                  field: "taxable_receipts",
                  type: "html",
                  render: rowData => {
                    return (
                      <div>
                        ${" "}
                        {rowData.taxable_receipts
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "RevPar",
                  field: "revpar",
                  type: "html",
                  render: rowData => {
                    return <div>$ {rowData.revpar.toFixed(2)}</div>;
                  },
                },
                {
                  title: "Taxpayer Name",
                  field: "taxpayer_name",
                  hidden: true,
                },
                {
                  title: "Taxpayer Number",
                  field: "taxpayer_number",
                  type: "numeric",
                  hidden: true,
                },
                {
                  title: "Taxpayer City",
                  field: "taxpayer_city",
                  hidden: true,
                },
                {
                  title: "Taxpayer County",
                  field: "taxpayer_county",
                  hidden: true,
                },

                {
                  title: "Taxpayer State",
                  field: "taxpayer_state",
                  hidden: true,
                },
                { title: "Taxpayer Zip", field: "taxpayer_zip", hidden: true },
              ]}
              data={this.state.rawposts}
              title={`Complete Data Set: ${this.state.postsHotelName}`}
              options={{
                columnsButton: true,
                loadingType: "linear",
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: this.state.displayMessage,
                },
              }}
            />
          </Grid>
        </Grid>

        <Tooltip title={this.state.title}>
          <Fab
            color="secondary"
            className={classes.absolute}
            onClick={this.handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Select a Portfolio from the dropdown to add this hotel to it"}
          </DialogTitle>
          <DialogContent>
            {errormsg}
            <TextField
              id="search_type"
              select
              label="Portfolio Type"
              className="portfolio-type"
              value={this.state.type}
              onChange={this.handleChange("type")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              {this.state.portfoliotypes.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.portfolio}
                </MenuItem>
              ))}
            </TextField>
            {this.state.noPortfoliosMsg ? (
              <p className={classes.noPortfolioMsg}>
                <p>You do not have any portfolios of this type created. </p>
                <Link className={classes.linkMsg} to="/stt/portfolio">
                  {" "}
                  Create one here.
                </Link>
              </p>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className="get-data"
              onClick={() => {
                this.handleClick();
              }}
            >
              Add to Portfolio
            </Button>

            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.opensnack}
          autoHideDuration={4000}
          onClose={this.handleCloseSnack}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              Hotel Added to
              <Link
                className={classes.portfolioLink}
                to={`/stt/portfoliohotel/${this.state.portfolioSelected.type}/${this.state.portfolioSelected.id}/${this.state.portfolioSelected.portfolio}`}
              >
                {this.state.portfolioSelected.portfolio}
              </Link>
            </span>
          }
          action={[]}
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </div>
    );
  }
}
HotelInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(HotelInfo));

// ======= POPOVER CLASS ========
class PopoverComponent extends Component {
  constructor(props) {
    super(props);
    //color = timelineDate[`${props.rowData.year}-${props.month}`].color;
    //console.log("popover Props", props);
    this.state = {
      openedPopoverId: "",
      anchorEl: "",
      month: "",
      classes: "",
      rowData: "",
      color: "",
      popOpen: false,
      timelineData: "",
    };
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  handlePopoverOpen(event, popoverId) {
    this.setState({
      openedPopoverId: popoverId,
      anchorEl: event.target,
    });
  }
  handlePopoverClose = () => {
    this.setState({
      openedPopoverId: null,
      anchorEl: null,
    });
  };

  componentWillMount() {
    let {
      month,
      classes,
      openedPopoverId,
      anchorEl,
      rowData,
      color,
      timelineDate,
    } = this.props;
    let colorBackground =
      timelineDate[`${this.props.rowData.year}-${this.props.month}`].color;
    this.setState({
      month,
      classes,
      openedPopoverId,
      anchorEl,
      rowData,
      timelineDate,
      color: colorBackground,
    });
  }

  render() {
    let {
      month,
      classes,
      openedPopoverId,
      anchorEl,
      rowData,
      color,
      timelineDate,
    } = this.state;

    return (
      <React.Fragment>
        <Popover
          id={month}
          className={classes.popover}
          style={{
            backgroundColor: `${color}!important`,
            opacity: ".5!important",
          }}
          open={openedPopoverId === month}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <div style={{ backgroundColor: `${color}` }}>
            <div className={classes.propertyChange}>
              <p style={{ marginBottom: "2px", marginTop: "0px" }}>
                {moment(
                  timelineDate[
                    `${rowData.year}-${month}`
                  ].responsibility_begin_date.toString()
                ).format("YYYY[-]MM[-]DD")}
                &nbsp;
              </p>
              <p style={{ marginBottom: "2px", marginTop: "0px" }}>
                Property started reporting with the name{" "}
                <em>
                  {timelineDate[`${rowData.year}-${month}`].location_name}
                </em>
                , under{" "}
                <em>
                  {timelineDate[`${rowData.year}-${month}`].taxpayer_name}
                </em>
              </p>
            </div>
          </div>
        </Popover>
        <span style={{ color: `${color}` }}>
          <Icon
            className={classes.newOwner}
            aria-owns={openedPopoverId ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={e => this.handlePopoverOpen(e, month)}
            onMouseLeave={this.handlePopoverClose}
          >
            contact_support
          </Icon>
        </span>
      </React.Fragment>
    );
  }
}
