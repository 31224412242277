import React from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 350,
    paddingTop: 100
  },
});

class GetUniMBTTable extends React.Component {
  constructor(props) {
    super(props)
    //console.log("this is props gtom mbt table");
    //console.log(props);
    this.state = {
      posts: [],
      token: '',
      postscity: '',
      isLoading: true,
      displayMessage: 'SEARCHING...'
      //query: this.props.queryFromSearch1
    };

    //console.log("this is the query from search");
    //console.log(this.props.queryFromSearch1);
  }


  // ${apiUrl}/rest/monthly/2/2015/city/harlingen
  // ${apiUrl}/rest/
  componentDidMount() {

    //console.log(`${this.props.match.params.month}/${this.props.match.params.year}/${this.props.match.params.item}/${this.props.match.params.itemvalue}`);
    axios.get(`${apiUrl}/rest/search/${this.props.match.params.type}/${this.props.match.params.item}/${this.props.match.params.itemvalue}`,
      {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token') //the token is a variable which holds the token
        }
      }).then(res => {

        try {
          const posts = res.data;
          if (posts.length === 0 || posts) {
            this.setState({ displayMessage: "No Records Found." })
          }
          this.setState({ 
            postscity: posts[0].location_city,
            posts,
            isLoading: false
          });
        } catch(err){
            handleError(err, pathname)
        }
       
      }).catch(e => {
        this.setState({ isLoading: false });
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }

  // make an url listener method and then pass to the did update???


  componentDidUpdate(nextProps, newUrl) {
    // console.log("this is next props");
    // console.log(nextProps);
    // console.log("this is next props location");
    // console.log(nextProps.location)
    //console.log("nextprops" +nextProps.queryFromSearch);
    // if (nextProps.queryFromSearch1 !== this.props.queryFromSearch1) {
    //   axios.get(this.props.queryFromSearch1,
    //     {
    //       headers: {
    //         authorization: 'Bearer ' + localStorage.getItem('token') //the token is a variable which holds the token
    //       }
    //     }).then(res => {
    //       //console.log(res);
    //       const posts = res.data;
    //       this.setState({ posts: posts });
    //     }).catch(e => {
    //       console.log(e);
    //     });
    // }
  }

  // handleClick(taxpayer_number, location_number, name, city, county) {
  //   ////console.log(taxpayer_number);
  //   axios({
  //     method: 'post',
  //     headers: { authorization: 'Bearer ' + localStorage.getItem('token') },
  //     url: '${apiUrl}/rest/history',
  //     data: { taxpayer_number: taxpayer_number, location_number: location_number, location_name: name, location_city: city, location_county: county, type: 1 }
  //   })
  //     .then((res) => {
  //       //console.log(res);
  //     }).catch(e => {
  //       console.log(e);
  //       axios({
  //         method: 'post',
  //         headers: { authorization: 'Bearer ' + localStorage.getItem('token') },
  //         url: '${apiUrl}/rest/errorlog',
  //         data: { error: e, url: window.location.href }
  //       }).then((res) => {
  //         console.log(res);
  //       })
  //     });
  // }

  render() {
    const { classes } = this.props;

    return (

      // <div className={classes.root} >
      //       <Fade
      //         in={this.state.isLoading}
      //         style={{
      //           transitionDelay: this.state.isLoading ? '800ms' : '0ms',
      //         }}
      //         unmountOnExit
      //       >
      //         <CircularProgress />
      //       </Fade>
      // </div>

      <Grid item xs={12}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <CSVLink data={this.state.posts} className="csvdownload" filename="MBTHotelSearchResults.csv">
          <Button className="csvbutton" title="Export As CSV">
            <Icon className="csvicon">save_alt</Icon>
          </Button>
        </CSVLink>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <MaterialTable
          columns={[
            {
              title: 'Name', field: 'name',
              render: rowData => {
                return (<Link to={`/stt/mbthotelinfo/${rowData.name.replace('/', "(slash)").replace('#', "(hash)")}/${rowData.taxpayer_number}/${rowData.location_number}`}
                >
                  {rowData.name}</Link>)
              },
              type: 'html'
            },
            { title: 'TaxPayer Number', field: 'taxpayer_number', hidden: true },
            { title: 'Address', field: 'address' },
            {
              title: 'City',
              render: rowData => { return (<Link to={`/stt/mbtcity/${rowData.city}`}>{rowData.city}</Link>) }
            },
            {
              title: 'County',
              render: rowData => { return (<Link to={`/stt/mbtcounty/${rowData.county}`}>{rowData.county}</Link>) }
            },
            { title: 'Zip', field: 'zip', hidden: false },

          ]}
          data={this.state.posts}
          title="MBT Search Results"
          options={{
            columnsButton: true,
            pageSize: 10,
            search: true,
            showEmptyDataSourceMessage: true,
            actionsColumnIndex: 10,
            loadingType: 'linear',
            //maxBodyHeight: 800

          }}
          localization={{
            body: {
              emptyDataSourceMessage: this.state.displayMessage,

            }
          }}

        />
        {/* {this.state.isLoading ?
                null :
              <React.Fragment>
                  Searching timing
              </React.Fragment>
            } */}
      </Grid>
    );
  }
}
export default withRouter(withStyles(styles)(GetUniMBTTable));