import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ReCaptcha } from 'react-recaptcha-google'
import { RECAPTCHA_SITE_KEY } from '../helpers';
import Footerhome from './Footerhome';
import ReactGA from 'react-ga';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';
import { AlertContext } from '../context/Alert/AlertContext';


// wont work in class based component since its using useContext Hook
// initilaize context
// const alertContext = useContext(AlertContext);
// // pulling out the setAlert function from contact
// const { setAlert } = alertContext;

let pathname = window.location.pathname;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
});




class Register extends React.Component {

  constructor(props) {
    super(props);  
    this.state = {
      fname: '',
      lname: '',
      email: this.props.match.params.email,
      password: '',
      vemail: '',
      redirect: false,
      complete: false,
      loginclick: false,
      errormsg: 0,
      logged: 0,
      // recaptcha variable
      recaptchastatus: false,
      recaptchaFail: false,
      recaptchaTriggerd: false
    };
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }
  initializeReactGA() {
    ReactGA.initialize('UA-80211377-3');
    ReactGA.pageview('/homepage');
  }

  static alertContext = AlertContext;

  onLoadRecaptcha() {
    try {
      if (this.captcha) {
        this.captcha.reset();
        this.captcha.execute();
      } 
    } catch(err){
        handleError(err, pathname)
    }
  }

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!  
    //console.log(recaptchaToken, "<= your recaptcha token")
    try {
      if (recaptchaToken && (recaptchaToken !== undefined || recaptchaToken !== null)) {
        this.setState({ reCaptchaToken: recaptchaToken })
      }
    } catch(err){
        handleError(err, pathname)
    }
  }


  componentDidMount() {
    document.title = "Sign Up - Search Texas Tax";

    if (localStorage.getItem('token'))
      this.setState({ logged: 1 })
    //loadReCaptcha(RECAPTCHA_SITE_KEY);
    ReactGA.initialize('UA-80211377-3');
    ReactGA.pageview('/homepage');
    //console.log(this.context);

    try {
      // execute invisible reCaptcha
      if (this.captcha) {
        this.captcha.reset();
        this.captcha.execute();
      }
    } catch(err){
        handleError(err, pathname)
    }
  }


  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleClick() {

    try {
      if (this.state.email === '' || this.state.password === '' || this.state.fname === '' || this.state.lname === '' || this.state.vemail === '') {
        //this.context.setAlert("this is a test");
        this.setState({ errormsg: 1 })
        return;
      }
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!(re.test(this.state.email))) {
        this.setState({ errormsg: 3 })
        return;
      }
      if (this.state.email !== this.state.vemail) {
        this.setState({ errormsg: 2 })
        return;
      }
    } catch(err){
        handleError(err, pathname)
    }
    
    // ===== would need to pass form data to AuthContext 'regsiter({})' function
    ReactGA.event({
      category: 'User',
      action: 'Created an Account'
    });
    //var bodyFormData = new FormData();
    //bodyFormData.set('email', this.state.email);
    // bodyFormData.set('password', this.state.password);
    axios({
      method: 'post',
      url: `${apiUrl}/rest/basic/userregister`,
      data: { fname: this.state.fname, lname: this.state.lname, email: this.state.email, password: this.state.password }
    }).then((res) => {

        try {
          if (res.data.result) {
            localStorage.setItem('token', res.data.token);
            this.setState({ redirect: true })
          } else {
            localStorage.removeItem('token');
            this.setState({ complete: true });
          }
          if (res.data === "This Email already exists") {
            this.setState({ errormsg: 4 });
          }
          // Recaptcha error check
          if (res.data === "Fail") {
            // alert user of recaptch fail
            this.setState({ recaptchaFail: true });
          }
        } catch(err){
            handleError(err, pathname)
        }
      }).catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
  }


  handleClicklogin() {
    this.setState({ loginclick: true })
  }

  componentDidUpdate(prevState) {
    if (this.state.recaptchaFail === true && this.state.recaptchaTriggerd === false) {
      //console.log("recaptcha faild, reset recaptcha");
      if (this.captcha) {
        //console.log("recaptcha from CDM", this.captcha)
        this.captcha.reset();
        this.captcha.execute();
        this.setState({ recaptchaTriggerd: true });
      }
    } 
  }


  render() {

    const { redirect, loginclick  } = this.state;
    const { classes } = this.props;

    //  need to make private route 
    if (this.state.logged === 1) {
      return <Redirect to='/stt/search' />;
    }
    if (redirect) {
      return <Redirect to='/paymentplan' />;
    }
    if (loginclick) {
      return <Redirect to='/login' />;
    }
    let complete;
    if (complete) {
      complete = (
        <p className="errormsg">This email already exists. Please enter a new one</p>
      )
    }
    let errormsg;
    if (this.state.errormsg === 1) {
      errormsg = (
        <p className="errormsg">Please Enter details to create an account.</p>
      )
    }
    else if (this.state.errormsg === 2) {
      errormsg = (
        <p className="errormsg">Email Addresses do not match. </p>
      )
    }
    else if (this.state.errormsg === 3) {
      errormsg = (
        <p className="errormsg">Please enter a valid Email. </p>
      )
    }
    else if (this.state.errormsg === 4) {
      errormsg = (
        <p className="errormsg">Email address already exists. </p>
      )
    }

    return (

      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>

            <a href="//searchtexastax.com">
              <Typography className="headerlogo" variant="h6" color="inherit" noWrap>

                <img className="logoimage" src="/stt-logo.svg" alt="logo" />
                <span> Search Texas Tax</span>
              </Typography>
            </a>
            <Button className="headerbutton" color="inherit" onClick={() => { this.handleClicklogin() }}>Login</Button>
          </Toolbar>
        </AppBar>
        <div className="register_text">
          <Typography variant="h4" color="inherit" className={classes.grow}>
            Create Your Account
          </Typography>
        </div>
        <Paper className="register_paper">

          <form className={classes.container} noValidate autoComplete="off">
            {errormsg}
            <TextField
              id="outlined-required"
              label="First Name"
              className={classes.textField}
              value={this.state.fname}
              onChange={this.handleChange('fname')}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-name"
              label="Last Name"
              className={classes.textField}
              value={this.state.lname}
              onChange={this.handleChange('lname')}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-name"
              label="Email"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-name"
              label="Verify Email"
              className={classes.textField}
              value={this.state.vemail}
              onChange={this.handleChange('vemail')}
              margin="normal"
              variant="outlined"
            />

            <TextField
              id="standard-password-input"
              label="Password"
              className={classes.textField}
              value={this.state.password}
              onChange={this.handleChange('password')}
              type="password"
              autoComplete="current-password"
              margin="normal"
              variant="outlined"
            />
            <div className="get-data">
              <Button variant="contained" size="large" color="primary" className={classes.button} onClick={() => { this.handleClick() }}>
                Register
              </Button>
            </div>
            <div className="signuplink">
              <Link to="/login"> Registered User? Login Here!! </Link>
            </div>
          </form>
        </Paper>
        {/* <ReCaptcha
          ref={(el) => { this.captcha = el; }}
          size="invisible"
          render="explicit"
          sitekey={RECAPTCHA_SITE_KEY}
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        /> */}
        <p className="recap">Secure Login with reCAPTCHA subject to Google <a href="https://policies.google.com/terms?hl=en" target="_blank" rel="noopener noreferrer" >Terms</a> &
            <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer" >Privacy</a>
        </p>
        <Footerhome />
      </div>
    );
  }
}


Register.propTypes = {
  classes: PropTypes.object.isRequired,
};

// let us access context --- now we are able to use 'this.context' to access context value
//Register.contextType = AlertContext;
export default withStyles(styles)(Register);