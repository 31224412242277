import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import ReactGA from 'react-ga';
import { FixedSizeList as List} from "react-window";
import { withRouter } from "react-router";
import { filters, months, quarters, years, searches } from './searchData/searchOptions';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';
import DownshiftSearch from './componentUtils/DownshiftSearch';

let pathname = window.location.pathname;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 4,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  select: {
    zIndex: 4,
    // position:'absolute'
  },
  downShiftWrapper: {
    marginLeft: '5px',
    paddingTop: '15px',
    width: '100%'
  },
  zipWrapper: {
    marginLeft: '5px',
    //paddingTop: '-50px',
    width: '100%'
  },
  
});



// Method that returns no option message
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}



// returns input
function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}



function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

//@StorageContext("your-storage-id")
class SearchBox extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      multiline: 'Controlled',
      filter: 'monthly',
      month: '',
      quarter: '2',
      year: '',
      search: 'city',
      showMonth: true,
      searchTerm: 1,
      single: null,
      multi: null,
      posts: [],
      zip: '',
      selectedOption: null,
      selectedOption1: null,
      cities: [],
      counties: [],
      errormsg: false,
      redirect: false,
      latestYear: '',
      latestMonth: '',
      // ===== extras
      showDetailed: false,
      hideSimple: false,
      isMounted: true,
      inputValue: '',
      selectedItem: [],
    };
    this.handleClick = this.handleClick.bind(this);
  }




  // Looks through local storage by key name and sets state
  // hydrateStateWithLocalStorage() {
  //   // for all items in state
  //   for (let key in this.state) {
  //     // if the key exists in localStorage
  //     if (localStorage.hasOwnProperty(key)) {
  //       // get the key's value from localStorage
  //       let value = localStorage.getItem(key);

  //       // parse the localStorage string and setState
  //       try {
  //         value = JSON.parse(value);
  //         this.setState({ [key]: value });
  //       } catch (e) {
  //         // handle empty string
  //         this.setState({ [key]: value });
  //       }
  //     }
  //   }
  //   //console.log(localStorage);
  // }

 
    handleDownShiftChange = item => {
      let { selectedItem } = this.state;
  
      if (selectedItem.indexOf(item) === -1) {
        selectedItem = [...selectedItem, item];
      }
  
      this.setState({
        inputValue: '',
        selectedItem,
        selectedOption1: selectedItem[0],
        selectedOption: selectedItem[0]
      });
    };


    DownshiftHandleKeyDown = event => {
      const { inputValue, selectedItem } = this.state;
      if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
        this.setState({
          selectedItem: selectedItem.slice(0, selectedItem.length - 1),
        });
      }
    };
  
    handleDownshiftInputChange = event => {
      this.setState({ inputValue: event.target.value });
    };
  
  

  // Takes in the name and set's the state of the selected elements name
  handleChange = name => event => {
    //console.log(event.target.value)
    this.setState({
      [name]: event.target.value
    });
    //localStorage.setItem(name, event.target.value);
    //console.log(localStorage);

    /* ===== Checking the user selection to output correct info ========= */
    if (event.target.value === "monthly" || event.target.value === "mbt") {
      this.setState(state => { state.showMonth = true; });
    }
    else if (event.target.value === "quarterly" || event.target.value === "quarterlymonthly") {
      this.setState(state => { state.showMonth = false; });
    }
    if (event.target.value === "city") {
      this.setState(state => { state.searchTerm = 1 });
    }
    else if (event.target.value === "county") {
      this.setState(state => { state.searchTerm = 2 });
    }
    else if (event.target.value === "zip") {
      this.setState({ searchTerm: 3 });
    }
  };



  /* ===== Method to handle set state of selectedOption or SelectedOption1 property ===== */
  handleChange1 = (selectedOption) => {
    this.setState({ selectedOption });
  }
  handleChangeTest = (selectedOption) => {
    this.setState({ selectedOption });
  }

  handleChange2 = (selectedOption1) => {
    this.setState({ selectedOption1 });
  }


  // method to set the from input state
  handleSimpleSearch = (formInput) => {
    this.setState({ formInput });
  }


  // Save all state to localstorage
  // saveStateToLocalStorage() {
  //   // for every item in React state
  //   for (let key in this.state) {
  //     // save to localStorage
  //     localStorage.setItem(key, JSON.stringify(this.state[key]));
  //   }
  // }

  // Method to handle user's click of button to select filter option
  handleClick(e) {
    e.preventDefault();
    let filterSelections = '';
    let searchValue = '';

    try {
      if (this.state.filter === "quarterly" || this.state.filter === "quarterlymonthly") {
        filterSelections = this.state.filter + "/" + this.state.quarter + "/" + this.state.year;
  
      } else {
        filterSelections = this.state.filter + "/" + this.state.month + "/" + this.state.year;
      }
  
      /*  ====== Check which Term is used ====== */
      if (this.state.searchTerm === 1) {
        // error handling....
        if (this.state.selectedOption == null) {
          this.setState({ errormsg: true });
        } else {
          this.setState({ errormsg: false })
        }
        //searchValue = "/city/" + this.state.selectedOption.value;
        searchValue = "/city/" + this.state.selectedOption;
      }
  
      if (this.state.searchTerm === 2) {
        if (this.state.selectedOption1 == null) {
          this.setState({ errormsg: true })
          return;
        }
        else {
          this.setState({ errormsg: false })
        }
        //searchValue = "/county/" + this.state.selectedOption1.value;
        searchValue = "/county/" + this.state.selectedOption1;
      }
  
      if (this.state.searchTerm === 3) {
        if (this.state.zip === '' || this.state.zip === null) {
          this.setState({ errormsg: true });
          return;
        } else if (this.state.zip) {
          this.setState({ errormsg: false })
          //searchValue = "/zip/" + this.state.zip;
          searchValue = "/zip/" + this.state.zip;
        }
      }
      this.props.history.push({ pathname: `/stt/results/${filterSelections}${searchValue}` });
    } catch(err){
        handleError(err, pathname)
    }
  }


  // ==== on initlization call cites and counties
  componentDidMount() {

    this.setState({ isMounted: true });
    //this.hydrateStateWithLocalStorage();
  
    try {

      // Making call to get Latest Month+Year DB updated
      axios.get(`${apiUrl}/rest/search/latest/`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token') //the token is a variable which holds the token
        }
      }).then(res => {
        //console.log(res.data);
        let latestYear = res.data.year.toString();
        let latestMonth = res.data.month.toString();
        // first make sure state is blank
        if (this.state.year === '' && this.state.month === '') {
          this.setState({ year: latestYear });
          this.setState({ month: latestMonth });
          //console.log(typeof this.state.latestMonth + ' ' + typeof this.state.latestYear);
        } else {
          this.setState({ year: 2019 });
          this.setState({ month: 1 });
        }
      }).catch(e => {
        ReactGA.exception({
          description: 'An error occurred'
        });
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
    } catch(err){
        handleError(err, pathname)
    }
    

  }
  
  componentWillUnmount() {

    this.setState({ isMounted: false });

    // window.removeEventListener(
    //   "beforeunload",
    //   this.saveStateToLocalStorage.bind(this)
    // );

    // saves if component has a chance to unmount
    //this.saveStateToLocalStorage();
  }


  render() {

    let errormsg;
    if (this.state.errormsg === true) {
      errormsg = (
        <p className="errormsg">Please Enter a city, county or zip code to view results</p>
      )
    }

    if (this.state.redirect === true) {
      window.location.replace('/logout')
    }
    const { classes } = this.props;
    const selectStyles = {
      input: base => ({
        ...base,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    const { selectedOption } = this.state;
    const { selectedOption1 } = this.state;


    let searchTerm;

    if (this.state.searchTerm === 1 ) {
      //  console.log(this.state.cities);

      // setting the compoenent to var searchTerm

      searchTerm = (
        <Grid className={classes.downShiftWrapper}>
          <DownshiftSearch
            className={classes.textField}
              type="city" 
              placeholder="ex: Austin"
              label="Start Typing a City Name" 
              filter={this.state.filter} 
              handleDownShiftChange={ this.handleDownShiftChange.bind(this)} 
              DownshiftHandleKeyDown={ this.DownshiftHandleKeyDown.bind(this)} 
              handleDownshiftInputChange={this.handleDownshiftInputChange.bind(this)} 
          />
        </Grid>
       
      )
    } else if (this.state.searchTerm === 2) {
      searchTerm = (
      <Grid className={classes.downShiftWrapper}>
        <DownshiftSearch 
        className={classes.textField}
          type="county" 
          placeholder="ex: Travis" 
          label="Start Typing a County Name"
          filter={this.state.filter}
          handleDownShiftChange={ this.handleDownShiftChange.bind(this)} 
          DownshiftHandleKeyDown={ this.DownshiftHandleKeyDown.bind(this)} 
          handleDownshiftInputChange={this.handleDownshiftInputChange.bind(this)} 
        />
        </Grid>
      )
    } else if (this.state.searchTerm === 3) {
     
      searchTerm = ( 
         <TextField
          id="outlined-name"
          label="Zip Code"
          className={classes.zipWrapper}
          value={this.state.zip}
          onChange={this.handleChange('zip')}
          margin="normal"
          variant="outlined"
        />
      )
    }

    return (
      <Grid item xs={12} spacing={3}>
        <Paper className="search_paper">
          <Grid>{errormsg}</Grid>
          <Typography variant="h5" component="h3">
            Classic Search
          </Typography>
          <p>
            <em>This is our legacy search. Complete the fields below to search.</em>
          </p>
          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleClick} >
            <Grid container>
              <Grid item xs={4} className="searchboxgrid">
                <TextField
                  id="filter_type"
                  select
                  label="Search Type"
                  className={classes.textField}
                  value={this.state.filter}
                  onChange={this.handleChange("filter")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {filters.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} className="searchboxgrid">
                {this.state.showMonth ?
                  <TextField id="month_type"
                    select
                    label="Month"
                    className={classes.textField}
                    value={this.state.month}
                    onChange={this.handleChange('month')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {months.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField> :

                  <TextField id="quarter_type"
                    select
                    label="Quarter"
                    className={classes.textField}
                    value={this.state.quarter.toString()}
                    onChange={this.handleChange('quarter')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {quarters.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              </Grid>
              <Grid item xs={4} className="searchboxgrid">
                <TextField id="year_type"
                  select
                  label="Year"
                  className={classes.textField}
                  value={this.state.year.toString()}
                  onChange={this.handleChange('year')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {years.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4} className="searchboxgrid">
                <TextField id="search_type"
                  select
                  label="Location"
                  className={classes.textField}
                  value={this.state.search}
                  onChange={this.handleChange('search')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {searches.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
           

              </Grid>
              <Grid item xs={4} className="searchboxgrid">
                  {searchTerm}
              </Grid>

              <Grid item xs={4} className="searchboxgrid get-data get-data-button">
                <Button variant="contained" size="large" color="primary" className={classes.button} onClick={this.handleClick}>
                  Get Data
                 </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    );
  }
}

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(SearchBox));