import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
});


class Login extends React.Component {

  state = {

    email: '',
    password: '',
    redirect: 0,
    errormsg: false,
    invalidmsg: false,
    loginclick: false,
    msg: false,
    passwordReset: 0,
    disabled: false

  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });

  };
  handleClicklogin() {

    this.setState({ loginclick: true })

  }

  handleClick() {

    try {
      if (this.state.email === '') {
        this.setState({ errormsg: true });
        return;
      }
      let counter = this.state.passwordReset + 1;
      this.setState({ passwordReset: counter });
      if (this.state.passwordReset === 0) {
  
        this.setState({ disabled: true });
        axios({
          method: 'post',
          url: `${apiUrl}/rest/passwordreset`,
          data: { email: this.state.email }
        })
          .then((res) => {
            this.setState({ msg: true });
            ////console.log(res);
  
          }).catch(e => {
            handleError(e, pathname);
            //window.location.replace('/logout');
          });
      }
    } catch(err){
        handleError(err, pathname)
    }
  }


  render() {
    let errormsg;
    if (this.state.errormsg === true) {
      errormsg = (
        <p className="errormsg">Please Enter an Email to get Password reset link</p>
      )
    }
    const { loginclick } = this.state;
    if (loginclick) {
      return <Redirect to='/login' />;
    }

    let msg;
    if (this.state.msg === true) {
      msg = (
        <p>Password Reset Link has been forwarded your email</p>
      )
    }
    else {
      msg = (<p>Please Enter your registered Email </p>)
    }
    let invalidmsg;
    if (this.state.invalidmsg === true) {
      invalidmsg = (
        <p className="errormsg">Invalid Email/ Password</p>
      )
    }


    const { classes } = this.props;


    return (

      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <a href="//searchtexastax.com">
              <Typography className="headerlogo" variant="h6" color="inherit" noWrap>

                <img className="logoimage" src="/stt-logo.svg" alt="img.svg" />
                <span> Search Texas Tax</span>
              </Typography>
            </a>
            <Button className="headerbutton" color="inherit" onClick={() => { this.handleClicklogin() }}>Login</Button>
          </Toolbar>
        </AppBar>
        <div className="register_text">
          <Typography variant="h5" color="inherit" className={classes.grow}>
            {msg}

          </Typography>
        </div>
        <Paper className="login_paper">
          {errormsg}

          {invalidmsg}

          <form className={classes.container} noValidate autoComplete="off">

            <TextField
              id="outlined-name"
              label="Email"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
              variant="outlined"
              validators={['required']}
              errorMessages={['this field is required']}
            />
            <div className="get-data">
              {this.state.disabled ?
                <Button variant="contained" size="large" color="primary" className={classes.button} disabled>
                  Sent
                        </Button>
                : <Button variant="contained" size="large" color="primary" className={classes.button} onClick={() => { this.handleClick() }} >
                  Submit
                        </Button>
              }

            </div>
          </form>
        </Paper>

      </div>



    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Login);

