import React, { Component } from 'react';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import { apiUrl } from '../../helpers';
import { withStyles } from '@material-ui/core/styles';
import { handleError } from '../../utils/errorHandling/errorHelper';
import ReactGA from 'react-ga';

const styles = theme => ({
  root: {
    flexGrow: 1,
    //height: 250,

  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',

  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,

  },
  divider: {
    height: theme.spacing.unit * 2,
  },

});


// func component for INPUT area
function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      variant="outlined"
      {...other}
    />
  );
}

//   renders the "dropwdown" suggestions
function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};




class DownshiftSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      filter: props.filter,
      type: props.type
    };
  }


  componentDidMount() {

    let { type, filter } = this.props;
    let query;

    // ==== determine query ======= 
    switch (true) {
      case (filter === "monthly" && type === "city"):
        query = `${apiUrl}/rest/basic/cities`;
        break;
      case (filter === "monthly" && type === "county"):
        query = `${apiUrl}/rest/basic/counties`;
        break;
      case (filter === "mbt" && type === "city"):
        query = `${apiUrl}/rest/mbt/cities`;
        break;
      case (filter === "mbt" && type === "county"):
        query = `${apiUrl}/rest/mbt/counties`;
        break;
      default:
      // no default
    }
    //console.log(query);


    // General api call to set options
    axios.get(query,
      {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token') //the token is a variable which holds the token
        }
      }).then(res => {
        if (type === "city") {
          let options = res.data.map(location => ({
            value: location.location_city,
            label: location.location_city
          }));
          this.setState({ options: options });
        } else if (type === "county") {
          let options = res.data.map(location => ({
            value: location.verified_county,
            label: location.verified_county
          }));
          this.setState({ options: options });
        }

      })

      .catch(e => {
        ReactGA.exception({
          description: 'An error occurred'
        })
        handleError(e);
        window.location.replace('/logout');
      });

  }



  componentDidUpdate(prevProps) {

    if (prevProps.filter !== this.props.filter || prevProps.type !== this.props.type) {
      //console.log("the component updated");
      let { type, filter } = this.props;
      let query;

      // ==== determine query ======= 
      switch (true) {
        case (filter === "monthly" && type === "city"):
          query = `${apiUrl}/rest/basic/cities`;
          break;
        case (filter === "monthly" && type === "county"):
          query = `${apiUrl}/rest/basic/counties`;
          break;
        case (filter === "mbt" && type === "city"):
          query = `${apiUrl}/rest/mbt/cities`;
          break;
        case (filter === "mbt" && type === "county"):
          query = `${apiUrl}/rest/mbt/counties`;
          break;
        case ((filter === "quarterly" || filter === "quarterlymonthly") && type === "city"):
          query = `${apiUrl}/rest/basic/cities`;
          break;
        case ((filter === "quarterly" || filter === "quarterlymonthly") && type === "county"):
          query = `${apiUrl}/rest/mbt/counties`;
          break;
        default:
        // no default
      }
      //console.log(query);


      // General api call to set options
      axios.get(query,
        {
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('token') //the token is a variable which holds the token
          }
        }).then(res => {
          if (type === "city") {
            let options = res.data.map(location => ({
              value: location.location_city,
              label: location.location_city
            }));
            this.setState({ options: options });
          } else if (type === "county") {
            let options = res.data.map(location => ({
              value: location.verified_county,
              label: location.verified_county
            }));
            this.setState({ options: options });
          }
        }).catch(e => {
          ReactGA.exception({
            description: 'An error occurred'
          });
          handleError(e);
          window.location.replace('/logout');
        });
    }

  }

  // method that takes user's input and filters options
  getSuggestions(value) {
    let { options } = this.state;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : options.filter(option => {
        const keep =
          count < 5 && option.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
  }


  render() {
    const { classes } = this.props;
    const { inputValue, selectedItem } = this.state;

    return (
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={this.props.handleDownShiftChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex,
        }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  startAdornment: selectedItem,
                  onChange: this.props.handleDownshiftInputChange,
                  onKeyDown: this.props.DownshiftHandleKeyDown,
                  placeholder: this.props.placeholder,
                }),
                label: this.props.label,
              })}
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {this.getSuggestions(inputValue2).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.label }),
                      highlightedIndex,
                      selectedItem: selectedItem2,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          )}
      </Downshift>
    );
  }
}

// DownshiftSearch.propTypes = {
//   classes: PropTypes.object.isRequired,
//   //options: PropTypes.array.isRequired,
// };




export default withStyles(styles)(DownshiftSearch);