import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  success: {
    color: '#43a047'
  }
});


class Login extends React.Component {


  state = {

    email: '',
    password: '',
    redirect: 0,
    msg: false,
    passwordchanged: false,
    invalidmsg: false,
    loginclick: false,
    showfield: true,
    errormsg: 0,
    disabled: false

  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
    ////console.log(event.target.value);
  };
  componentDidMount() {
    axios({
      method: 'post',
      url: `${apiUrl}/rest/passwordreset/check`,
      headers: {
        authorization: 'Bearer ' + this.props.match.params.token, //the token is a variable which holds the token
      }

    })
      .then((res) => {
        ////console.log(res.data);
        if (res.data === false) {
          this.setState({ msg: true });

        }
      }).catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });

  }


  handleClick() {
    if (this.state.password === '' || this.state.vpassword === '') {
      this.setState({ errormsg: 2 });
      return;
    }

    if (this.state.password !== this.state.vpassword) {
      this.setState({ errormsg: 1 });
      return;

    }
    else {
      this.setState({ errormsg: 0 });
    }

    this.setState({ disabled: true })
    axios({
      method: 'post',
      url: `${apiUrl}/rest/passwordreset/update`,
      headers: {
        authorization: 'Bearer ' + this.props.match.params.token, //the token is a variable which holds the token
      },
      data: { password: this.state.password }
    })
      .then((res) => {
        ////console.log(res.data);

        this.setState({ passwordchanged: true });


      }).catch(e => {
        handleError(e, pathname);
      });
  }

  render() {
    const { loginclick } = this.state;
    if (loginclick) {
      return <Redirect to='/register' />;
    }
    let errormsg;
    if (this.state.errormsg === 1) {
      errormsg = (
        <p className="errormsg">Passwords do not match.</p>
      )
    }
    else if (this.state.errormsg === 2) {
      errormsg = (
        <p className="errormsg">Please enter new password to submit.</p>
      )
    }
    let showbox;
    if (this.state.msg === true) {

      showbox = (
        <p className="errormsg">Your token has expired. Please click <Link to="/passwordrequest">here</Link> to request a new password reset link..</p>
      )
    }
    else {
      showbox = (
        <form noValidate autoComplete="off">

          <TextField
            id="standard-password-input"
            label="Password"

            value={this.state.password}
            onChange={this.handleChange('password')}
            type="password"
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="standard-password-input"
            label="Confirm Password"

            value={this.state.vpassword}
            onChange={this.handleChange('vpassword')}
            type="password"
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
          />


          <div className="get-data">
            {this.state.disabled ?
              <Button variant="contained" size="large" color="primary" {...this.state.disabled} disabled>
                Password Reset
                                  </Button>
              :

              <Button variant="contained" size="large" color="primary" {...this.state.disabled} onClick={() => { this.handleClick() }}>
                Change Password
                                </Button>
            }

          </div>
        </form>
      )
    }

    let passwordchanged;
    if (this.state.passwordchanged === true) {
      passwordchanged = (
        <p style={{color: "#43a047"}}>Password has been changed successfully. Please <Link to="/login">click here</Link> to login</p>
      )
    }

    let invalidmsg;
    if (this.state.invalidmsg === true) {
      invalidmsg = (
        <p className="errormsg">Invalid Email/ Password</p>
      )
    }
    const { redirect } = this.state;

    if (redirect === 1) {
      return <Redirect to='/stt/search' />;
    }

    const { classes } = this.props;

    return (

      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>

            <Typography variant="h6" color="inherit" className={classes.grow}>
              Search Texas Tax
          </Typography>
            <Button color="inherit" onClick={() => { this.handleClicklogin() }}>Register</Button>
          </Toolbar>
        </AppBar>
        <div className="register_text">
          <Typography variant="h5" color="inherit" className={classes.grow}>
            Reset Password
          </Typography>
        </div>
        <Paper className="login_paper">
          {passwordchanged}
          {errormsg}
          {showbox}
          {invalidmsg}


        </Paper>

      </div>



    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Login);

