import React from 'react'
import axios from 'axios'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper';
import { Chart } from "react-google-charts";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import MenuItem from "@material-ui/core/MenuItem";
import ReactGA from 'react-ga';
import TextField from '@material-ui/core/TextField';
import pink from '@material-ui/core/colors/pink';
import green from '@material-ui/core/colors/green';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';


let pathname = window.location.pathname;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fab: {
    margin: theme.spacing.unit * 2
  },
  avatar: {
    margin: 0,
  },
  pinkAvatar: {
    margin: 0,
    color: '#fff',
    backgroundColor: pink[500],
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 15
  },
  greenAvatar: {
    margin: 0,
    color: '#fff',
    backgroundColor: green[500],
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 15
  },
  absolute: {
    position: "relative",
    bottom: theme.spacing.unit * 10,
    left: theme.spacing.unit * 170
  }, 
  link: {
    color: 'blue!important',
    textDecoration: 'underline'
  }
});

const durations = [
  {
    value: "2017",
    label: "1 year"
  },
  {
    value: "2015",
    label: "3 years"
  },
  {
    value: "2013",
    label: "5 years"
  },
  {
    value: "2008",
    label: "10 years"
  },
  {
    value: "2000",
    label: "All"
  }
]
const durations1 = [
  {
    value: "2017",
    label: "1 year"
  },
  {
    value: "2015",
    label: "3 years"
  },
  {
    value: "2013",
    label: "5 years"
  },
  {
    value: "2008",
    label: "10 years"
  },
  {
    value: "2000",
    label: "All"
  }
]


class PortfolioHotel extends React.Component {
  constructor(props){
    super(props);
    //console.log(props);
  }
  state = {
    posts: [],
    postsrev: [],
    token: '',
    dataLoadingStatus: 'loading',
    dataLoadingStatusrev: 'loading',
    chartData: [],
    chartDatarev: [],
    value: 0,
    type: '',
    portfolio: '',
    errormsg: false,
    rows: [],
    columns: [],
    durations: "2017",
    durations1: "2017",
    labelWidth: 0,
    latestrows: [],
    noPostsMsg: false,
    portfolioName: '',
    currentPortfolio: ''
  }


  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChange1 = (event, value) => {
    this.setState({ value });
  };

  handleChangerev = name => event => {
    this.setState({
      [name]: event.target.value,
  });

      try {
        let {postsrev} = this.state;
        let revcolumns = [{ type: 'date', label: 'Month' }];
        var revrows = new Array();
        let countrev = 1;
        var query = '';

        postsrev.forEach(post => {
          if (this.props.match.params.type == 0) {
            query = `${apiUrl}/rest/basic/${post.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${post.taxpayer_number}/${post.location_number}`;

            axios.get(query,
              {
                headers: {
                  authorization: 'Bearer' + ' ' + localStorage.getItem('token'), //the token is a variable which holds the token
                }
              }).then(res => {
              
                var currentd = new Date();
                var current = currentd.getFullYear();

                const [metadata, data] = res.data
                const a = {
                  type: 'number',
                  label: post.location_name
                }
                revcolumns.push(a);

                if (countrev === 1) {
                  for (let row of res.data) {
                    const { date, revpar } = row
                    if (row.year > event.target.value && row.year <= current) {
                      date = new Date(row.year + "-" + row.month);
                      date = new Date(row.year, row.month - 1, 1);
                      revrows.push([date + "", revpar]);
                    }
                  }
                  countrev = countrev + 1;
                } else {

                  for (let row of res.data) {
                    const { date, revpar } = row
                    if (row.year > event.target.value && row.year <= current) {
                      date = new Date(row.year + "-" + row.month);
                      date = new Date(row.year, row.month - 1, 1);
                      let ab = new Date(Date.parse(new Date(date)));
                      let i = 0;
                      let flag = 0;
                      for (i = 0; i < revrows.length; i++) {
                        if (revrows[i][0].includes(ab)) {
                          revrows[i][countrev] = row.revpar;
                          flag = 1;
                          break;
                        }
                      }
                      if (flag == 0) {
                        revrows.push([date + ""]);
                        revrows[revrows.length - 1][countrev] = row.revpar;
                      }
                    }

                  }
                  countrev = countrev + 1;
                }//end of if else
              }).then((res) => {
                this.setState({ revrows: revrows, revcolumns: revcolumns });
                if (this.state.revcolumns.length === this.state.postsrev.length + 1) {

                  for (let m = 0; m < this.state.revrows.length; m++) {
                    this.state.revrows[m][0] = new Date(this.state.revrows[m][0]);
                    for (let n = 1; n < this.state.postsrev.length + 1; n++) {
                      if (!this.state.revrows[m][n])
                        this.state.revrows[m][n] = 0;
                    }
                  }
                  this.state.revrows = this.state.revrows.sort(function (a, b) {
                    return a[0] - b[0];
                  });
                  this.setState({
                    chartDatarev: [this.state.revcolumns, ...this.state.revrows],
                    dataLoadingStatusrev: 'ready',
                  });
                }
              }).catch(e => {
                handleError(e, pathname);
              });//End of axios
          }
          else if (this.props.match.params.type == 1) {
            query = `${apiUrl}/${post.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${post.taxpayer_number}/${post.location_number}`;
          }
        });//End of loop

      } catch(err){
          handleError(err, pathname)
      }

         
  }

  handleChangeportfolio = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    ////console.log(event.target.value);

      try {
        axios({
          method: 'get',
          url: `${apiUrl}/rest/portfolio/hotel/${this.props.match.params.id}`,
          headers: {
            authorization: 'Bearer' + ' ' + localStorage.getItem('token'), //the token is a variable which holds the token
          },
    
        })
          .then((res) => {
            const posts = res.data;
            this.setState({ posts: posts });
    
            let columns = [
              { type: 'date', label: 'Month' }
            ];
            //          let rows = [];
            var rows = new Array();
            let count = 1;
            posts.forEach(post => {
    
              // if type is hotel
              if (this.props.match.params.type == 0) {
                axios.get(`${apiUrl}/rest/basic/${post.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${post.taxpayer_number}/${post.location_number}`,
                  {
                    headers: {
                      authorization: 'Bearer' + ' ' + localStorage.getItem('token'), //the token is a variable which holds the token
                    }
                  }).then(res => {
                    ////console.log(res);
                    const ttmposts = res.data;
                    //console.log(ttmposts);
                    const [metadata, data] = res.data
                    const a = {
                      type: 'number',
                      label: post.location_name
                    }
                    columns.push(a);
                    var currentd = new Date();
                    var current = currentd.getFullYear();
                    //let current = moment().format('YYYY')
                    if (count === 1) {
                      for (let row of res.data) {
    
                        const { date, room_receipts } = row
                        if (row.year >= event.target.value && row.year <= current) {
                          date = new Date(row.year + "-" + row.month);
                          date = new Date(row.year, row.month - 1, 1);
                          rows.push([date + "", room_receipts ]);
                        }
                        //console.log("rows", rows)
                      }
                      count = count + 1;
                    } else {
    
                      for (let row of res.data) {
                        const { date, room_receipts } = row
                        if (row.year >= event.target.value && row.year <= current) {
                          date = new Date(row.year + "-" + row.month);
                          date = new Date(row.year, row.month - 1, 1);
                          let ab = new Date(Date.parse(new Date(date)));
                          //console.log("ab is the date....", ab)
                          let i = 0;
                          let flag = 0;
                          //  ////console.log(rows.length);
                          for (i = 0; i < rows.length; i++) {
                            if (rows[i][0].includes(ab)) {
                              rows[i][count] = row.room_receipts;
                              flag = 1;
                              break;
                            }
                          }
                          if (flag == 0) {
                            //console.log("Dates mismatcjh");
                            rows.push([date + ""]);
                            rows[rows.length - 1][count] = row.room_receipts;
                          }
                        }
                      }
                      count = count + 1;
                    }//end of if else
    
                  }).then((res) => {
    
                    this.setState({ rows: rows, columns: columns });

                    if (this.state.columns.length === this.state.posts.length + 1) {
    
                      for (let m = 0; m < this.state.rows.length; m++) {
                        this.state.rows[m][0] = new Date(this.state.rows[m][0]);
                        for (let n = 1; n < this.state.posts.length + 1; n++) {
                          if (!this.state.rows[m][n])
                            this.state.rows[m][n] = 0;
                        }
                      }
                      this.state.rows = this.state.rows.sort(function (a, b) {
                        return a[0] - b[0];
                      });
    
                      this.setState({
                        chartData: [this.state.columns, ...this.state.rows],
                        dataLoadingStatus: 'ready',
                      });
                    }
    
                  }).catch(e => {
                    handleError(e, pathname);
                    //window.location.replace('/logout');
                  });//End of axios
              }
              else if (this.props.match.params.type == 1) {
    
                axios.get(`${apiUrl}/rest/mbt/${post.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${post.taxpayer_number}/${post.location_number}`,
                  {
                    headers: {
                      authorization: 'Bearer' + ' ' + localStorage.getItem('token'), //the token is a variable which holds the token
                    }
                  }).then(res => {
                    //console.log(res);
                    const [metadata, data] = res.data
                    const a = {
                      type: 'number',
                      label: post.location_name
                    }
                    columns.push(a);
                    var currentd = new Date();
                    var current = currentd.getFullYear();
                    if (count === 1) {
                      for (let row of res.data) {
    
                        const { date, total_receipts } = row
    
                        if (row.year > event.target.value && row.year <= current) {
                          date = new Date(row.year + "-" + row.month);
                          date = new Date(row.year, row.month - 1, 1);
                          rows.push([date + "", total_receipts]);
                        }
                      }
                      count = count + 1;
                    } else {
                      for (let row of res.data) {
                        const { date, total_receipts } = row
                        if (row.year >= event.target.value && row.year <= current) {
                          date = new Date(row.year + "-" + row.month);
                          date = new Date(row.year, row.month - 1, 1);
                          let ab = new Date(Date.parse(new Date(date)));
                          let i = 0;
                          let flag = 0;

                          for (i = 0; i < rows.length; i++) {
                            if (rows[i][0].includes(ab)) {
                              rows[i][count] = row.total_receipts;
                              flag = 1;
                              break;
                            }
                          }
                          if (flag == 0) {
                            rows.push([date + ""]);
                            rows[rows.length - 1][count] = row.total_receipts;
                          }
                        }
                      }
                      count = count + 1;
                    }//end of if else
    
                  }).then((res) => {
    
                    this.setState({ rows: rows, columns: columns });

                    if (this.state.columns.length === this.state.posts.length + 1) {
    
                      for (let m = 0; m < this.state.rows.length; m++) {
                        this.state.rows[m][0] = new Date(this.state.rows[m][0]);
                        for (let n = 1; n < this.state.posts.length + 1; n++) {
                          if (!this.state.rows[m][n])
                            this.state.rows[m][n] = 0;
                        }
                      }
                      this.state.rows = this.state.rows.sort(function (a, b) {
                        return a[0] - b[0];
                      });
    
                      this.setState({
                        chartData: [this.state.columns, ...this.state.rows],
                        dataLoadingStatus: 'ready',
                      });
                    }
    
                  }).catch(e => {
                    handleError(e, pathname);
                    //window.location.replace('/logout');
                  });
              }
            });//End of loop
    
          }).catch(e => {
            handleError(e, pathname);
            //window.location.replace('/logout');
          });

      } catch(err){
          handleError(err, pathname)
      }
  }

  // Delete From Portfolio
  handleClickDelete(id, taxpayer_number, location_number, location_name) {

    axios({
      method: 'delete',
      url: `${apiUrl}/rest/portfolio/hotel`,
      headers: {
        authorization: 'Bearer' + ' ' + localStorage.getItem('token'), //the token is a variable which holds the token
      },
      data: { id: id, taxpayer_number: taxpayer_number, location_number: location_number, location_name: location_name }
    })
      .then((res) => {
        this.setState({ open: false });
        axios({
          method: 'get',
          url: `${apiUrl}/rest/portfolio/hotel/${this.props.match.params.id}`,
          headers: {
            authorization: 'Bearer' + ' ' + localStorage.getItem('token'), //the token is a variable which holds the token
          },

        })
          .then((res) => {
            const posts = res.data;
            this.setState({ posts: posts });
            //////console.log(this.props.match.params.id);
            //////console.log(res.data);
            let columns = [
              { type: 'date', label: 'Month' }];

            var rows = new Array();

            let count = 1;
            posts.forEach(post => {
              ////console.log(post.location_name);
              axios.get(`${apiUrl}/rest/basic/${post.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${post.taxpayer_number}/${post.location_number}`,
                {
                  headers: {
                    authorization: 'Bearer' + ' ' + localStorage.getItem('token'), //the token is a variable which holds the token
                  }
                }).then(res => {
                  ////console.log(res);
                  const [metadata, data] = res.data
                  const a = {
                    type: 'number',
                    label: post.location_name
                  }
                  columns.push(a);

                  if (count === 1) {
                    for (let row of res.data) {
                      const { date, room_receipts } = row
                      date = new Date(row.year + "-" + row.month);
                      rows.push([new Date(Date.parse(date)) + "", room_receipts]);
                    }

                    count = count + 1;
                  } else {

                    for (let row of res.data) {
                      const { date, room_receipts } = row
                      date = new Date(row.year + "-" + row.month);
                      let ab = new Date(Date.parse(new Date(row.year + "-" + row.month)));
                      let i = 0;
                      let flag = 0;
                      //  ////console.log(rows.length);
                      for (i = 0; i < rows.length; i++) {
                        if (rows[i][0].includes(ab)) {
                          rows[i][count] = row.room_receipts;
                          //   ////console.log("Dates Matched");
                          flag = 1;
                          break;
                        }
                      }
                      if (flag == 0) {
                        //////console.log(rows[i][0]+ab);
                        //    ////console.log("Dates mismatcjh");
                        rows.push([new Date(Date.parse(date)) + ""]);
                        rows[rows.length - 1][count] = row.room_receipts;
                      }



                      /*  for(let i = 0;i<rows.length;i++){
                        //  rows[i][0]= new Date(rows[i][0]);
                            if(!rows[i][count]){
                                rows[i][count]= 0;
                            }
                        }*/
                    }



                    count = count + 1;
                  }//end of if else

                }).then((res) => {
                  this.setState({ rows: rows, columns: columns });
                  if (this.state.columns.length === this.state.posts.length + 1) {

                    for (let m = 0; m < this.state.rows.length; m++) {
                      this.state.rows[m][0] = new Date(this.state.rows[m][0]);
                      for (let n = 1; n < this.state.posts.length + 1; n++) {
                        if (!this.state.rows[m][n])
                          this.state.rows[m][n] = 0;
                      }
                    }
                    this.state.rows = this.state.rows.sort(function (a, b) {
                      return a[0] - b[0];
                    });
                    this.setState({
                      chartData: [this.state.columns, ...this.state.rows],
                      dataLoadingStatus: 'ready',
                    });
                  }

                }).catch(e => {
                  handleError(e, pathname);
                  //window.location.replace('/logout');
                });//End of axios
            });//End of loop

          }).catch(e => {
            handleError(e, pathname);
            //window.location.replace('/logout');
          });

      }).catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });




  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });

  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ errormsg: false });
    this.setState({ portfolio: '' });
  };


  componentDidMount() {
    document.title = "Portfolio Hotels - Search Texas Tax";
    ReactGA.initialize('UA-80211377-3');
    ReactGA.pageview(window.location.pathname);

     // Making call to porfolios again to filter out the name of the current portflio being viewed, 
    //  So that i'm not soley relying on the params (woudl users can change)
    axios({
      method: 'get',
      url: `${apiUrl}/rest/portfolio`,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
      }
    })
      .then((res) => {
       let portfolios = res.data;
       let currentPortfolio = portfolios.filter((portfolio => {
         return portfolio.id === parseInt(this.props.match.params.id)
       }))
       this.setState({ currentPortfolio })
       this.setState({portfolioName: currentPortfolio[0].portfolio});
      

      }).catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });


    axios({
      method: 'get',
      url: `${apiUrl}/rest/portfolio/hotel/${this.props.match.params.id}`,
      headers: {
       authorization: 'Bearer'+' '+ localStorage.getItem('token'), //the token is a variable which holds the token
        },
      }).then((res) => {
            const posts = res.data;
            if(posts.length > 0){
              this.setState({ posts:posts });
            } else {
              this.setState({ noPostsMsg: true });
            }
            let columns = [{type: 'date',label:'Month'}];
//          let rows = [];
            var rows= new Array();
            let count = 1;
            var query='';

            

            // make query for each property
            posts.forEach(post => {
                 if(this.props.match.params.type==0)
                 {
                 query = `${apiUrl}/rest/basic/${post.location_name.replace('/',"(slash)").replace('#',"(hash)")+"/"+post.taxpayer_number+"/"+post.location_number}`;
                axios.get(query,
                    {
                        headers: {
                            authorization: 'Bearer'+' '+ localStorage.getItem('token'), //the token is a variable which holds the token
                        }
                    }).then(res => {
                          const ttmrawposts = res.data;
                          //console.log(ttmrawposts);
                          var currentd = new Date();
                          var current = currentd.getFullYear();
                          const [metadata, data] = res.data

                          // colums for each property
                          const a = {
                            type: 'number',
                            label: post.location_name
                          }
                          columns.push(a);
                          // first loop through, show one year on inital page load.
                          if(count ===1){
                            for (let row of res.data) {
                                const { date, room_receipts, location_name} = row
                                // get year data from 2017 - 2019
                                if(row.year>2016 && row.year<=current)
                                {
                                  date = new Date(row.year+"-"+row.month);
                                  date = new Date(row.year, row.month-1, 1);
                                  rows.push([date+"", room_receipts || 0]);
                                }
                            }
                            count = count+1;
                          }else {

                            // continue
                            for (let row of res.data) {

                                const { date, room_receipts, location_name} = row;

                                if(row.year>2016 && row.year<=current){
                                  //date = new Date(row.year+"-"+row.month);
                                  date = new Date(row.year,row.month-1,1);
                                  let ab = new Date(Date.parse(new Date(date)));
                                  let i = 0;
                                  let flag = 0;

                                  // then loop through rows, if first one has the formatted date, set the room receipts
                                  for( i = 0;i<rows.length;i++){
                                    if(rows[i][0].includes(ab)){
                                        rows[i][count]= row.room_receipts || 0;
                                        flag = 1;
                                        break;
                                    }
                                  }
                                  if(flag==0){
                                    rows.push([date+""]);
                                    rows[rows.length-1][count]= row.room_receipts || 0;
                                  }
                                 }
                            }
                            count = count + 1;
                          }//end of if else


                      }).then((res)=>{
                          this.setState({rows:rows,columns:columns});
                          if(this.state.columns.length===this.state.posts.length+1){

                            for(let m =0 ; m <this.state.rows.length;m++){
                              this.state.rows[m][0] = new Date(this.state.rows[m][0]);
                                for(let n = 1; n < this.state.posts.length +1 ; n++){
                                  if(!this.state.rows[m][n])
                                    //this.state.rows[m][n]=null;
                                    this.state.rows[m][n]=0;
                                }
                            }
                            this.state.rows = this.state.rows.sort(function(a,b) {
                              return a[0] - b[0];
                              });

                            this.setState({
                              chartData: [this.state.columns, ...this.state.rows],
                              dataLoadingStatus: 'ready',
                            });
                          //  console.log(this.state.columns);
                          //  console.log(this.state.rows);
                          }

                      }).catch(e => {
                        handleError(e, pathname);
                           //console.log(e);
                      });//End of axios
                  }
                else if(this.props.match.params.type==1){
                      query = `${apiUrl}/rest/mbt/${post.location_name.replace('/',"(slash)").replace('#',"(hash)")+"/"+post.taxpayer_number+"/"+post.location_number}`;

                      axios.get(query,
                            {
                                headers: {
                                    authorization: 'Bearer'+' '+ localStorage.getItem('token'), //the token is a variable which holds the token
                                }
                            }).then(res => {
                            //console.log(res);
                            const [metadata, data] = res.data
                            const a = {
                              type: 'number',
                              label: post.location_name
                            }
                            columns.push(a);
                            var currentd = new Date();
                            var current = currentd.getFullYear();
                            if(count ===1){
                              for (let row of res.data) {

                                  const { date, total_receipts} = row

                                  if(row.year>2016 && row.year<=current)
                                  {
                                    //date = new Date(row.year+"-"+row.month);
                                    date = new Date(row.year,row.month-1,1);
                                    rows.push([date+"", total_receipts ]);
                                  }
                              }
                              count = count+1;
                            }else {

                              for (let row of res.data) {
                                  const { date, total_receipts} = row
                                  if(row.year>2016 && row.year<=current){
                                    //date = new Date(row.year+"-"+row.month);
                                    date = new Date(row.year,row.month-1,1);
                                    let ab = new Date(Date.parse(new Date(date)));
                                    let i = 0;
                                    let flag = 0;
                      
                                  for( i = 0;i<rows.length;i++){
                                      if(rows[i][0].includes(ab)){
                                          rows[i][count]= row.total_receipts;
                                          flag = 1;
                                          break;
                                      }
                                  }
                                        if(flag==0){
                                        rows.push([date+""]);
                                        rows[rows.length-1][count]= row.total_receipts;
                                      }
                                   }
                              }
                              count = count + 1;
                            }//end of if else

                        }).then((res)=>{


                          //////console.log("Came inside axios done and should be 2");
                          this.setState({rows:rows,columns:columns});

                           // ////console.log(this.state.rows.length);
                          if(this.state.columns.length===this.state.posts.length+1){

                            for(let m =0 ; m <this.state.rows.length;m++){
                              this.state.rows[m][0] = new Date(this.state.rows[m][0]);
                                for(let n = 1; n < this.state.posts.length +1 ; n++){
                                  if(!this.state.rows[m][n])
                                    this.state.rows[m][n]=0;
                                }
                            }
                            this.state.rows = this.state.rows.sort(function(a,b) {
                              return a[0] - b[0];
                            });

                              this.setState({
                                chartData: [this.state.columns, ...this.state.rows],
                                dataLoadingStatus: 'ready',
                              });
                             // ////console.log(this.state.columns);
                             // ////console.log(this.state.rows);
                            }

                        }).catch(e => {
                          handleError(e, pathname);
                          //console.log(e);
                        });//End of axios
                      }
            });//End of loop

       }).catch(e => {
          handleError(e, pathname);
            //console.log(e);
        });

        //RevPar
        axios({
            method: 'get',
            url: `${apiUrl}/rest/portfolio/hotel/${this.props.match.params.id}`,
            headers: {
             authorization: 'Bearer'+' '+ localStorage.getItem('token'), //the token is a variable which holds the token
              },

          }).then((res) => {
                  const postsrev = res.data;
                  this.setState({ postsrev:postsrev });

                  let revcolumns = [
                  {type: 'date',label:'Month'}];
        //          let rows = [];
                  var revrows= new Array();
                  let countrev = 1;
                  var query='';
                  postsrev.forEach(post => {
                       if(this.props.match.params.type==0)
                       {
                       query = `${apiUrl}/rest/basic/${post.location_name.replace('/',"(slash)").replace('#',"(hash)")+"/"+post.taxpayer_number+"/"+post.location_number}`;


                      axios.get(query,
                          {
                              headers: {
                                  authorization: 'Bearer'+' '+ localStorage.getItem('token'), //the token is a variable which holds the token
                              }
                          }).then(res => {
                                var currentd = new Date();
                                var current = currentd.getFullYear();

                                const [metadata, data] = res.data
                                const a = {
                                  type: 'number',
                                  label: post.location_name
                                }
                                revcolumns.push(a);

                                if(countrev ===1){
                                  for (let row of res.data) {
                                      const { date, revpar} = row
                                      if(row.year>2016 && row.year<=current)
                                      {
                                      //date = new Date(row.year+"-"+row.month);
                                        date = new Date(row.year,row.month-1,1);
                                        revrows.push([date+"", revpar]);
                                      }
                                  }
                                  countrev= countrev+1;
                                }else {

                                  for (let row of res.data) {
                                      const { date, revpar} = row
                                      if(row.year>2016 && row.year<=current){
                                        //date = new Date(row.year+"-"+row.month);
                                        date = new Date(row.year,row.month-1,1);
                                        let ab = new Date(Date.parse(new Date(date)));
                                        let i = 0;
                                        let flag = 0;
                                        for( i = 0;i<revrows.length;i++){
                                            if(revrows[i][0].includes(ab)){
                                                revrows[i][countrev]= row.revpar;
                                                flag = 1;
                                                break;
                                            }
                                        }
                                        if(flag==0){
                                            revrows.push([date+""]);
                                            revrows[revrows.length-1][countrev]= row.revpar;
                                          }
                                       }
                                  }
                                  countrev =countrev + 1;
                                }//end of if else


                            }).then((res)=>{
                                this.setState({revrows:revrows,revcolumns:revcolumns});
                                if(this.state.revcolumns.length===this.state.postsrev.length+1){

                                  for(let m =0 ; m <this.state.revrows.length;m++){
                                    this.state.revrows[m][0] = new Date(this.state.revrows[m][0]);
                                      for(let n = 1; n < this.state.postsrev.length +1 ; n++){
                                        if(!this.state.revrows[m][n])
                                          this.state.revrows[m][n]=0;
                                      }
                                  }
                                  this.state.revrows = this.state.revrows.sort(function(a,b) {
                                    return a[0] - b[0];
                                  });

                                  this.setState({
                                    chartDatarev: [this.state.revcolumns, ...this.state.revrows],
                                    dataLoadingStatusrev: 'ready',
                                  });
                                 // ////console.log(this.state.revcolumns);
                                 // ////console.log(this.state.rows);
                                }

                            }).catch(e => {
                              handleError(e, pathname);
                                   //console.log(e);
                            });//End of axios
                        } else if(this.props.match.params.type==1){
                            query = `${apiUrl}/rest/mbt/${post.location_name.replace('/',"(slash)").replace('#',"(hash)")+"/"+post.taxpayer_number+"/"+post.location_number}`;

                        }
                  });//End of loop

             }).catch(e => {
               handleError(e, pathname);
                  //console.log(e);
            });
  }



  render() {

    const { classes, theme } = this.props;
    const { auth, anchorEl, noPostsMsg } = this.state;
    const open = Boolean(anchorEl);
    const { value } = this.state;

    let chartdisplay;
    if (this.state.dataLoadingStatus === "ready") {
      chartdisplay = (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="outlined-select-currency"
              select
              className={classes.textField}
              value={this.state.durations}
              onChange={this.handleChangeportfolio('durations')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"

            >
              {durations.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <Chart
            chartType="Line"
            loader={<CircularProgress className="loading" size={50} />}
            width={'100%'}
            height={'400px'}
            data={this.state.chartData}
            options={{
              chartArea: { height: '100%', width: '90%' },
              hAxis: {
                format: 'yyyy',
              },
              vAxis: {
                format: 'short',
              },
              title: 'Monthly Revenue',
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: 'Total_Revenue' },

              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Revenue: { label: 'Monthly Revenue' },
                  all: {
                    format: {
                      pattern: 'decimal'
                    }
                  }
                }
              }
            }}
            rootProps={{ 'data-testid': '2' }}
          />
        </div>
      )
    }
    else {
      chartdisplay = (
        <div className="loadingparent"> <CircularProgress className="loading" size={50} /></div>
      )
    }

    let chartdisplay1;
    if (this.state.dataLoadingStatusrev == "ready") {
      chartdisplay1 = (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="outlined-select-currency"
              select
              className={classes.textField}
              value={this.state.durations1}
              onChange={this.handleChangerev('durations1')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {durations.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <Chart
            chartType="Line"
            loader={<div>Loading Chart</div>}
            width={'100%'}
            height={'400px'}
            data={this.state.chartDatarev}
            options={{
              chartArea: { height: '100%', width: '90%' },
              hAxis: {
                format: 'yyyy',
              },
              vAxis: {
                format: 'decimal',
              },
              title: 'Taxable Revenue',
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: 'Total_Revenue' },

              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Revenue: { label: 'Monthly Revenue' },
                  all: {
                    format: {
                      pattern: 'decimal'
                    }
                  }
                }
              }
            }}
            rootProps={{ 'data-testid': '2' }}
          />
        </div>
      )
    }
    else {
      chartdisplay1 = (
        <div className="loadingparent"> <CircularProgress className="loading" size={50} /></div>
      )
    }
    let rev;
    if (this.props.match.params.type == 0) {
      rev = (
        <Tab label="RevPAR" />
      )

    }
    let lengthpost;
    if (this.state.posts.length > 0) {
      lengthpost = (
        <div>
          <Paper className="table-material">
            <MaterialTable
              columns={[
                {
                  title: 'Hotel Name',
                  render: rowData => {
                    if (this.props.match.params.type == 0) {
                      return (<Link to={`/stt/hotelinfo/${rowData.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${rowData.taxpayer_number}/${rowData.location_number}`}>
                        {rowData.location_name.replace('(slash)', "/").replace('(hash)', "#")}</Link>)
                    }
                    else if (this.props.match.params.type == 1) {
                      return (<Link to={`/stt/mbthotelinfo/${rowData.location_name}/${rowData.taxpayer_number}/${rowData.location_number}`}>
                        {rowData.location_name}</Link>)

                    }
                  }
                },
                {
                  title: 'Option',
                  render: rowData => {
                    return (

                      <IconButton aria-label="Delete" onClick={() => { this.handleClickDelete(rowData.id, rowData.taxpayer_number, rowData.location_number, rowData.location_name) }}>
                        <DeleteIcon />
                      </IconButton>
                    )
                  }
                }
              ]}
              data={this.state.posts}
              title='Available Properties'
              options={{
                pageSize: 5
              }}
            />
          </Paper>
          <Paper className="portfolio-chart">
            <AppBar position="static">
              <Tabs value={value} onChange={this.handleChange1} scrollable scrollButtons="auto">
                <Tab label="Monthly Revenue" />
                {rev}
              </Tabs>
            </AppBar>
            {value === 0 && <TabContainer> {chartdisplay}</TabContainer>}
            {value === 1 && <TabContainer>{chartdisplay1}</TabContainer>}
          </Paper>
        </div>
      )
    }

    const { portfolioName} = this.state;
    return (

      <Grid container spacing={3} className="searchgrid">
        <Grid item xs={12}>
          <Paper className="data_title">
            <div className="row">
              <div className="col">
                <h1>{portfolioName ? portfolioName : null}</h1>
              </div>
              <div className="col">
                <Typography color="textSecondary">
                  Hotel Portfolio
                </Typography>
                {noPostsMsg ? <p><span className="errormsg">No properties yet saved.</span> Start Searching <Link className={classes.link} to ="/stt/search">here!</Link></p> : null}
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {lengthpost}
        </Grid>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Grid>



    );
  }
}
PortfolioHotel.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(PortfolioHotel);