import React from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 350,
    paddingTop: 100
  },
});

class GetMBTTable extends React.Component {
  constructor(props) {
    super(props)
    //console.log("this is props gtom mbt table");
    //console.log(props);
    this.state = {
      posts: [],
      token: '',
      postscity: '',
      isLoading: true,
      displayMessage: 'SEARCHING...'
      //query: this.props.queryFromSearch1
    };

  }


  // ${apiUrl}/rest/monthly/2/2015/city/harlingen
  componentDidMount() {

 
    axios.get(`${apiUrl}/rest/${this.props.match.params.type}/${this.props.match.params.month}/${this.props.match.params.year}/${this.props.match.params.item}/${this.props.match.params.itemvalue}`,
      {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token') //the token is a variable which holds the token
        }
      }).then(res => {

        try {

          const posts = res.data;
          if (posts.length === 0 || posts) {
            this.setState({ displayMessage: "No Records Found." })
          }
          this.setState({ 
            postscity: posts[0].location_city,
            posts,
            isLoading: false
          });
        } catch(err){
          handleError(err, pathname)
        }
      }).catch(e => {
        this.setState({ isLoading: false });
        handleError(e, pathname);
       // window.location.replace('/login');
      });
  }



  render() {
    const { classes } = this.props;

    return (

      <Grid item xs={12}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <CSVLink data={this.state.posts} className="csvdownload" filename="MBTHotelSearchResults.csv">
          <Button className="csvbutton" title="Export As CSV">
            <Icon className="csvicon">save_alt</Icon>
          </Button>
        </CSVLink>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <MaterialTable
          columns={[
            {
              title: 'Name', field: 'location_name',
              render: rowData => {
                return (<Link to={`/stt/mbthotelinfo/${rowData.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${rowData.taxpayer_number}/${rowData.location_number}`}
                >
                  {rowData.location_name}</Link>)
              },
              type: 'html'
            },
            { title: 'Address', field: 'location_address' },
            {
              title: 'City',
              render: rowData => { return (<Link to={`/stt/mbtcity/${rowData.location_city}`}>{rowData.location_city}</Link>) }
            },
            {
              title: 'County',
              render: rowData => { return (<Link to={`/stt/mbtcounty/${rowData.verified_county}`}>{rowData.verified_county}</Link>) }
            },
            { title: 'Zip', field: 'location_zip', hidden: true },
            { title: 'Year', field: 'year', type: 'numeric', hidden: true },
            { title: 'Wine Receipts', field: 'wine_receipts', type: 'numeric' },
            { title: 'Liquor Receipts', field: 'liquor_receipts', type: 'numeric' },
            { title: 'Beer Receipts', field: 'beer_receipts', type: 'numeric' },
            {
              title: 'Total Revenue', field: 'total_receipts', type: 'html',
              render: rowData => { return (<div>$ {rowData.total_receipts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
            },

          ]}
          data={this.state.posts}
          title="MBT Search Results"
          options={{
            columnsButton: true,
            pageSize: 10,
            search: true,
            showEmptyDataSourceMessage: true,
            actionsColumnIndex: 10,
            loadingType: 'linear',
            //maxBodyHeight: 800
          }}
          localization={{
            body: {
              emptyDataSourceMessage: this.state.displayMessage,
            }
          }}
        />
      </Grid>
    );
  }
}
export default withRouter(withStyles(styles)(GetMBTTable));

